import MapCollection from "collections/map";
import Vue from "vue";
import salasService from "@/Services/SalasService";
import $handleError from "@/plugins/handleError";

const state = () => ({
  salas: [],
  legendas: [],
  carregandoSalas: false,
  salasLoaded: false,
  salasList: [],
  salasCookpitList: [],
});
const mutations = {
  setSalas(state, salas) {
    const salasMap = new MapCollection();
    salas.map((sala) => salasMap.set(sala.id, sala));
    Vue.set(state, "salas", salasMap);
    Vue.set(state, "salasList", Array.from(salasMap.values()));
    Vue.set(state, "salasLoaded", true);
    // state = {...state, salas: salasMap, salasLoaded: true}
  },
  setCookpitSalas(state, salas) {
    const salasMap = new MapCollection();
    salas.map((sala) => salasMap.set(sala.id, sala));
    Vue.set(state, "salas", salasMap);
    Vue.set(state, "salasCookpitList", Array.from(salasMap.values()));
    Vue.set(state, "salasLoaded", true);
    // state = {...state, salas: salasMap, salasLoaded: true}
  },
  setCookpitSalasLegenda(state, legendas) {
    const legendasMap = new MapCollection();
    legendas.map((legenda) => legendasMap.set(legenda.color, legenda));
    Vue.set(state, "legendas", legendasMap);
    Vue.set(state, "salasCookpitLegendaList", Array.from(legendasMap.values()));
    Vue.set(state, "salasLoaded", true);
    // state = {...state, salas: salasMap, salasLoaded: true}
  },
  setCarregandoSalas(state, newValue) {
    state.carregandoSalas = newValue;
  },
  adicionarSala(state, sala) {
    state.salas.set(sala.id, sala);
    Vue.set(state, "salas", state.salas);
    Vue.set(state, "salasList", Array.from(state.salas.values()));
  },
  atualizarSala(state, sala) {
    state.salas.set(sala.id, sala);
    Vue.set(state, "salas", state.salas);
    Vue.set(state, "salasList", Array.from(state.salas.values()));
  },
  deletarSala(state, salaId) {
    state.salas.delete(salaId);
    Vue.set(state, "salas", state.salas);
    Vue.set(state, "salasList", Array.from(state.salas.values()));
  },
};
const actions = {
  async loadSalas({ commit }, options = {}) {
    commit("setCarregandoSalas", true);
    try {
      const salasList = await salasService.syncAll(options.force);
      commit("setSalas", salasList);
    } catch (error) {
      $handleError(error);
    }
    commit("setCarregandoSalas", false);
  },
  async loadCookpitSalas({ commit }, options = {}) {
    commit("setCarregandoSalas", true);
    try {
      const salasCookpitList = await salasService.cookpitSalas(options.force);
      commit("setCookpitSalas", salasCookpitList);
    } catch (error) {
      $handleError(error);
    }
    commit("setCarregandoSalas", false);
  },
  async loadCookpitSalasLegenda({ commit }, options = {}) {
    commit("setCarregandoSalas", true);
    try {
      const salasCookpitLegendaList = await salasService.cookpitSalasLegenda(options.force);
      commit("setCookpitSalasLegenda", salasCookpitLegendaList);
    } catch (error) {
      $handleError(error);
    }
    commit("setCarregandoSalas", false);
  },
};
const getters = {
  salas: (state) => state.salas,
  carregandoSalas: (state) => state.carregandoSalas,
  salasLoaded: (state) => state.salasLoaded,
  salasCookpitList: (state) => state.salasCookpitList,
  salasCookpitLegendaList: (state) => state.salasCookpitLegendaList,
  salasList: (state) => state.salasList,
};
const namespaced = true;
export default {
  state,
  mutations,
  actions,
  getters,
  namespaced,
};
