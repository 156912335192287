import Axios from "@/plugins/Axios";
import SubcategoriaSala from "@/Models/SubcategoriaSala";

export class SubcategoriaSalaService {
  async syncAll() {
    const response = await Axios().get("subcategoria-salas");
    const subcategorias = response.data.map((subcategoria) => new SubcategoriaSala(subcategoria));
    // store.commit("SubcategoriaSala/setSubcategoriaSala", subcategorias);
    return subcategorias;
  }

  async criarSubcategoriaSala(subcategoria) {
    const response = await Axios().post("subcategoria-salas", subcategoria);
    const novaSubcategoria = response.data;
    // store.commit("Subcategoria/adicionarSubcategoria", novaSubcategoria);
    return novaSubcategoria;
  }

  async atualizarSubcategoriaSala(subcategoria) {
    const response = await Axios().put(`subcategoria-salas/${subcategoria.id}`, subcategoria);
    const subcategoriaAtualizada = response.data;
    // store.commit("Subcategoria/atualizarSubcategoria", subcategoriaAtualizada);
    return subcategoriaAtualizada;
  }

  async deletarSubcategoriaSala(serie) {
    const response = await Axios().delete(`subcategoria-salas/${serie.id}`);
    const subcategoriaDeletada = response.data;
    // store.commit("Subcategoria/deletarSubcategoria", serie.id);
    return subcategoriaDeletada;
  }

  // async recuperarSerie(serie) {
  //   return this.atualizarSerie({ ...serie, deleted_at: null });
  // }
}
export default new SubcategoriaSalaService();
