<template>
  <main-template>
    <v-row>
      <v-col>
        <span class="text-h4">Etapas</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pt-0 pb-0">
        <div class="d-flex mb-2 justify-space-between">
          <v-btn color="success" @click="criarEtapa" class="mr-2">Criar Etapa</v-btn>
          <v-text-field
            v-model="search"
            class="ml-2 search-input"
            append-icon="mdi-magnify"
            label="Pesquisar..."
            solo
            autofocus
            dense
            single-line
            hide-details
          />
        </div>
      </v-col>
      <v-col class="pt-0">
        <v-data-table
          :headers="table.headers"
          :items="etapasList"
          :loading="carregandoEtapas"
          :items-per-page="10"
          :custom-filter="dataTableFilter"
          :search="search"
          class="elevation-1"
        >
          <template v-slot:body="{ items }">
            <tbody name="scroll-x-transition" is="transition-group" duration="150">
              <tr
                color="primary"
                :class="etapa.estaDeletado ? ['deleted'] : []"
                v-for="etapa of items"
                :key="etapa.id"
              >
                <td>{{ etapa.id }}</td>
                <td>{{ etapa.descricao }}</td>
                <td>{{ etapa.circuito.descricao }}</td>
                <td>{{ etapa.periodo_inicial }}</td>
                <td>{{ etapa.periodo_final }}</td>
                <!-- <td>{{ etapa.contexto }}</td> -->
                <td>
                  <v-tooltip v-if="!etapa.estaDeletado" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="blue-grey lighten-3"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => editarEtapa(etapa)"
                      >
                        <v-icon small>fa fa-edit </v-icon>
                      </v-btn>
                    </template>
                    <span>Editar etapa</span>
                  </v-tooltip>
                  <v-tooltip v-if="!etapa.estaDeletado" color="error" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="error"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => deletarEtapa(etapa)"
                      >
                        <v-icon small>fa fa-trash </v-icon>
                      </v-btn>
                    </template>
                    <span>Apagar etapa</span>
                  </v-tooltip>
                  <v-tooltip v-if="etapa.estaDeletado" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="info"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => recuperarEtapa(etapa)"
                      >
                        <v-icon small>fa fa-sync </v-icon>
                      </v-btn>
                    </template>
                    <span>Recuperar etapa</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </main-template>
</template>

<script>
import { mapGetters } from "vuex";
import { dataTableFilter } from "@/plugins/searchQuery";

export default {
  computed: {
    ...mapGetters("Etapas", ["etapasList", "carregandoEtapas"]),
  },
  data() {
    return {
      dataTableFilter,
      search: "",
      table: {
        headers: [
          { text: "#", value: "id" },
          // { text: "Código", value: "codigo" },
          { text: "Nome", value: "descricao" },
          { text: "Circuito", value: "circuito" },
          { text: "Início", value: "inicio" },
          { text: "Fim", value: "fim" },
          // { text: "Contexto", value: "contexto" },
          { text: "Ações", value: "actions", sortable: false },
        ],
      },
    };
  },
  mounted() {
    this.$store.dispatch("Etapas/loadEtapas");
  },
  watch: {
    carregandoEtapas(value) {
      if (value && this.etapasList.length === 0) {
        this.$loaderService.open("Carregando Etapas");
      } else {
        this.$loaderService.close();
      }
    },
  },
  methods: {
    criarEtapa() {
      this.$router.push({ name: "etapas.create" });
    },
    editarEtapa(etapa) {
      this.$router.push({ name: "etapas.edit", params: { etapa_id: etapa.id } });
      // return etapa;
    },
    async deletarEtapa(etapa) {
      this.$modals.danger({
        title: "Cuidado!",
        message: "Tem certeza que deseja apagar esta etapa?",
        confirmationCode: etapa.id,
        confirmationMessage: `Por favor, digite <strong>${etapa.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Deletando a etapa");
              try {
                await this.$services.etapasService.deletarEtapa(etapa);
                this.$toast.success("etapa deletada com sucesso");
                this.$forceUpdate();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              // text: true,
              small: true,
            },
          },
        ],
      });
    },
    recuperarEtapa(etapa) {
      this.$modals.warn({
        title: "Atenção!",
        message: "Tem certeza que deseja recuperar esta etapa?",
        confirmationCode: etapa.id,
        confirmationMessage: `Por favor, digite <strong>${etapa.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Recuperando a etapa");
              try {
                await this.$services.etapasService.recuperarEtapa(etapa);
                this.$toast.success("etapa recuperada com sucesso");
                this.$forceUpdate();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              // text: true,
              small: true,
            },
          },
        ],
      });
    },
  },
};
</script>

<style></style>
