<template>
  <main-template>
    <v-row>
      <v-col>
        <span class="text-h4">Horários </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pt-0 pb-0">
        <div class="d-flex mb-2 justify-space-between">
          <v-btn color="success" @click="criarHorario" class="mr-2">Criar Horário</v-btn>
          <v-text-field
            v-model="search"
            class="ml-2 search-input"
            append-icon="mdi-magnify"
            label="Pesquisar..."
            solo
            autofocus
            dense
            single-line
            hide-details
          />
        </div>
      </v-col>
      <v-col class="pt-0">
        <v-data-table
          :headers="table.headers"
          :items="horariosList"
          :loading="carregandoHorarios"
          :items-per-page="10"
          :custom-filter="dataTableFilter"
          :search="search"
          class="elevation-1"
        >
          <template v-slot:body="{ items }">
            <tbody name="scroll-x-transition" is="transition-group" duration="150">
              <tr
                color="primary"
                :class="serie.estaDeletado ? ['deleted'] : []"
                v-for="serie of items"
                :key="serie.id"
              >
                <td>{{ serie.id }}</td>
                <td>{{ serie.ano ? serie.ano.descricao : "- - -" }}</td>
                <td>{{ serie.descricao }}</td>
                <!-- <td>{{ serie.contexto }}</td> -->
                <td>
                  <v-tooltip v-if="!serie.estaDeletado" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="blue-grey lighten-3"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => editarHorario(serie)"
                      >
                        <v-icon small>fa fa-edit </v-icon>
                      </v-btn>
                    </template>
                    <span>Editar serie</span>
                  </v-tooltip>
                  <v-tooltip v-if="!serie.estaDeletado" color="error" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="error"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => deletarSerie(serie)"
                      >
                        <v-icon small>fa fa-trash </v-icon>
                      </v-btn>
                    </template>
                    <span>Apagar serie</span>
                  </v-tooltip>
                  <v-tooltip v-if="serie.estaDeletado" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="info"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => recuperarHorario(serie)"
                      >
                        <v-icon small>fa fa-sync </v-icon>
                      </v-btn>
                    </template>
                    <span>Recuperar serie</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </main-template>
</template>

<script>
import { mapGetters } from "vuex";
import { dataTableFilter } from "@/plugins/searchQuery";

export default {
  computed: {
    ...mapGetters("Horarios", ["horariosList", "carregandoHorarios"]),
  },
  data() {
    return {
      dataTableFilter,
      search: "",
      table: {
        headers: [
          { text: "#", value: "id" },
          // { text: "Código", value: "codigo" },
          { text: "Ano", value: "ano" },
          { text: "Nome", value: "descricao" },
          // { text: "Contexto", value: "contexto" },
          { text: "Ações", value: "actions", sortable: false },
        ],
      },
    };
  },
  mounted() {
    this.$store.dispatch("Horarios/loadHorarios");
  },
  watch: {
    carregandoHorarios(value) {
      if (value && this.horariosList.length === 0) {
        this.$loaderService.open("Carregando horarios");
      } else {
        this.$loaderService.close();
      }
    },
  },
  methods: {
    criarHorario() {
      this.$router.push({ name: "horarios.create" });
    },
    editarHorario(horario) {
      this.$router.push({ name: "horarios.edit", params: { horario_id: horario.id } });
      // return horario;
    },
    async deletarSerie(horario) {
      this.$modals.danger({
        title: "Cuidado!",
        message: "Tem certeza que deseja apagar esta horario?",
        confirmationCode: horario.id,
        confirmationMessage: `Por favor, digite <strong>${horario.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Deletando a Horario");
              try {
                await this.$services.horariosService.deletarSerie(horario);
                this.$toast.success("Horario deletada com sucesso");
                this.$forceUpdate();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              // text: true,
              small: true,
            },
          },
        ],
      });
    },
    recuperarHorario(horario) {
      this.$modals.warn({
        title: "Atenção!",
        message: "Tem certeza que deseja recuperar esta horario?",
        confirmationCode: horario.id,
        confirmationMessage: `Por favor, digite <strong>${horario.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Recuperando a Horario");
              try {
                await this.$services.horariosService.recuperarHorario(horario);
                this.$toast.success("Horario recuperada com sucesso");
                this.$forceUpdate();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              // text: true,
              small: true,
            },
          },
        ],
      });
    },
  },
};
</script>

<style></style>
