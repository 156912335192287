import Axios from "@/plugins/Axios";
import store from "@/store";

export class RolesService {
  async syncAll() {
    const response = await Axios().get("roles");
    // const roles = response.data((role) => new Role(role));
    // store.commit("Roles/setRoles", roles);
    return response.data.roles;
  }

  async criarRole(role) {
    const response = await Axios().post("roles", role);
    const novaRole = response.data;
    store.commit("Roles/adicionarRole", novaRole);
    return novaRole;
  }

  async atualizarRole(role) {
    const response = await Axios().put(`roles/${role.id}`, role);
    const roleAtualizada = response.data;
    store.commit("Roles/atualizarRole", roleAtualizada);
    return roleAtualizada;
  }

  async allUsers(data) {
    const response = await Axios().put(`roles/allUsers`, data);
    return response;
  }

  async deletarRole(role) {
    const response = await Axios().roles(`roles/${role.id}`);
    const roleDeletada = response.data;
    store.commit("Roles/deletarRole", role.id);
    return roleDeletada;
  }

  async recuperarRole(role) {
    return this.atualizarRole({ ...role, deleted_at: null });
  }
}
export default new RolesService();
