import Model from "./Model";

export const SituacaoCategoriaSalaType = {
  ATIVA: 1,
  DESATIVADA: 0,
};

export default class CategoriaSala extends Model {
  constructor(franquia) {
    super();
    Object.assign(this, franquia);
  }

  get ativa() {
    return this.situacao === SituacaoCategoriaSalaType.ATIVA;
  }

  get desativada() {
    return this.situacao === SituacaoCategoriaSalaType.DESATIVADA;
  }
}
