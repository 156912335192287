<template>
  <v-col cols="12" md="3" sm="12">
    <v-card :loading="loading" class="mx-auto" height="180">
      <v-card-text class="text-h6 font-weight-black"> Matriculas </v-card-text>

      <v-row>
        <v-col cols="7">
          <v-card-text class="text-h4 font-weight-black">
            {{ qtd }}
          </v-card-text>
        </v-col>

        <v-col cols="5">
          <apexchart type="area" height="150" :options="chartOptions" :series="series"></apexchart>
        </v-col>
      </v-row>
      <!-- <template slot="progress">
        <v-progress-linear color="blue" indeterminate></v-progress-linear>
      </template>

      <v-card-text class="text-h6 font-weight-light d-flex align-center justify-space-between"
        >Matriculas </v-card-text
      >
      <v-card-text class="d-flex justify-space-between">
        <h2>
          {{ qtd }}
        </h2>

        <apexchart class="ml-4" type="area" height="150" :options="chartOptions" :series="series"></apexchart>
        <v-card
          style="border: none"
          color="grey"
          class="lighten-2 d-flex align-center justify-space-between"
        >
        </v-card>
      </v-card-text>-->
    </v-card>
  </v-col>
</template>

<script>
export default {
  name: "MatriculasCard",
  mounted() {
    this.getQtd();
  },
  data() {
    return {
      qtd: 0,
      loading: true,
      series: [
        {
          name: "Matriculas",
          data: [80, 88, 55, 88, 80, 88, 55, 88, 80, 88, 55, 88],
        },
      ],
      chartOptions: {
        chart: {
          height: 150,
          type: "area",
          toolbar: {
            show: false,
          },
          offsetY: -30,
        },

        dataLabels: {
          enabled: false,
        },

        grid: {
          show: false,
        },

        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            // stops: [0, 90, 100],
          },
        },

        yaxis: {
          show: false,
        },

        responsive: [
          {
            breakpoint: 200,
            options: {
              legend: {
                position: "top",
                offsetY: -80,
              },
            },
          },
        ],

        xaxis: {
          labels: {
            show: false,
          },
          categories: [
            "Jan",
            "Fev",
            "Mar",
            "Abr",
            "Mai",
            "Jun",
            "Jul",
            "Ago",
            "Set",
            "Out",
            "Nov",
            "Dez",
          ],
        },
      },
    };
  },
  methods: {
    async getQtd() {
      const response = await this.$services.matriculasService.qtd();

      this.qtd = response.qtd;

      const data = response.metricasPorMes.map((q) => q.qtd);

      this.series = [];

      this.series.push({
        name: "Matriculas",
        data: [...data],
      });

      this.loading = false;
    },
  },
};
</script>

<style scoped>
div.chart-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
