<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="() => $router.push({ name: 'salas' })"
            :disabled="submittingForm"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          {{ editing ? "Editando" : "Criando" }} Sala
        </h1>
      </v-col>
    </v-row>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submitForm)" :disabled="submittingForm">
        <div class="d-flex">
          <v-col class="pt-0 pb-0" cols="12">
            <ValidationProvider name="description" rules="required" v-slot="{ errors }">
              <e-label>Descrição</e-label>
              <v-text-field
                v-on:change="(value) => deixarLetraMinuscula(value, 'description')"
                :error-messages="errors"
                v-model="form.description"
                dense
                solo
              />
            </ValidationProvider>
          </v-col>
        </div>
        <div class="d-flex">
          <v-col class="pt-0 pb-0" cols="5">
            <ValidationProvider name="status" rules="required" v-slot="{ errors }">
              <e-label>Status</e-label>
              <e-autocomplete
                :items="$constants.statusSala"
                :return-object="false"
                :item-value="(value) => value.slug"
                :item-text="(value) => value.descricao"
                :error-messages="errors"
                v-model="form.status"
                label="Selecione uma opção"
                dense
                solo
              />
            </ValidationProvider>
          </v-col>
        </div>
        <div class="d-flex flex-column flex-lg-row">
          <v-col class="pt-0 pb-0" cols="5">
            <ValidationProvider name="categoria_de_sala_id" rules="required" v-slot="{ errors }">
              <e-label>Categoria</e-label>
              <e-autocomplete
                :items="categoriaSalasList"
                :return-object="false"
                :item-text="(categoria) => categoria.description"
                :item-value="(categoria) => categoria.id"
                :error-messages="errors"
                v-model="form.categoria_de_sala_id"
                label="Selecione uma opção"
                dense
                solo
              />
            </ValidationProvider>
          </v-col>
          <v-col class="mt-0 mt-lg-4" cols="1">
            <e-form-dialog type="categoria" />
          </v-col>
          <v-col class="pt-0 pb-0" cols="5">
            <ValidationProvider name="subcategoria_de_sala_id" rules="required" v-slot="{ errors }">
              <e-label>Subcategoria</e-label>
              <e-autocomplete
                :items="subcategoriaSalasList"
                :return-object="false"
                :item-text="(subcategoriaSalasList) => subcategoriaSalasList.description"
                :item-value="(sub) => sub.id"
                :error-messages="errors"
                v-model="form.subcategoria_de_sala_id"
                label="Selecione uma opção"
                dense
                solo
              />
            </ValidationProvider>
          </v-col>
          <v-col class="mt-4" cols="1">
            <e-form-dialog type="subcategoria" />
          </v-col>
        </div>
        <div class="d-flex">
          <v-col cols="2">
            <v-btn
              color="primary"
              :disabled="submittingForm"
              :loading="submittingForm"
              @click="() => submitForm()"
              block
              >Enviar Dados</v-btn
            >
          </v-col>
        </div>
      </form>
    </ValidationObserver>
  </main-template>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ELabel from "../../components/ELabel.vue";
import EFormDialog from "../../components/Salas/EFormDialog.vue";

export default {
  components: { ELabel, EFormDialog },
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters("CategoriaSalas", ["categoriaSalasList", "carregandoCategoriaSalas"]),
    ...mapGetters("SubcategoriaSalas", ["subcategoriaSalasList", "carregandoSubcategoriaSalas"]),
    ...mapGetters("Salas", ["salas"]),
    form() {
      if (!this.salas || !this.editing) return {};
      const { sala_id } = this.$route.params;
      const sala = this.salas.get(parseInt(sala_id, 10));
      return { ...sala };
    },
  },
  mounted() {
    this.loadData();
  },
  data() {
    return {
      submittingForm: false,
    };
  },
  methods: {
    ...mapActions("CategoriaSalas", ["loadCategoriaSala"]),
    ...mapActions("SubcategoriaSalas", ["loadSubcategoriaSala"]),
    ...mapActions("Salas", ["loadSalas"]),
    deixarLetraMinuscula(value, campo) {
      const novo_valor = value.toLowerCase().split(" ");
      const valor = novo_valor
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
      this.form[campo] = valor;
    },
    async submitForm() {
      this.submittingForm = true;
      try {
        if (this.editing) {
          await this.$services.salasService.atualizarSala(this.form);
        } else {
          await this.$services.salasService.criarSala(this.form);
        }
        this.$toast.success("Sala Atualizada com sucesso");
        this.$router.push({ name: "salas" });
      } catch (error) {
        this.$handleError(error);
      }
      this.submittingForm = false;
    },
    async loadData() {
      try {
        this.$loaderService.open("Carregando dados");
        await Promise.all([
          this.loadCategoriaSala(),
          this.loadSubcategoriaSala(),
          this.loadSalas(),
        ]);
        this.$loaderService.close();
        this.$forceUpdate();
      } catch (error) {
        this.$handleError(error);
      }
    },
  },
};
</script>

<style></style>
