import Axios from "@/plugins/Axios";
import store from "@/store";
import Sala from "@/Models/Sala";
import Legenda from "@/Models/Legenda";

export class SalasService {
  async syncAll() {
    const response = await Axios().get("salas");
    const salas = response.data.map((sala) => new Sala(sala));
    store.commit("Salas/setSalas", salas);
    return salas;
  }

  async salasDeAula() {
    const response = await Axios().get("salas?categoria=sala_de_aula");
    const salas = response.data.map((sala) => new Sala(sala));
    store.commit("Salas/setSalas", salas);
    return salas;
  }

  async criarSala(sala) {
    const response = await Axios().post("salas", sala);
    const novaSala = response.data;
    store.commit("Salas/adicionarSala", novaSala);
    return novaSala;
  }

  async atualizarSala(sala) {
    const response = await Axios().put(`salas/${sala.id}`, sala);
    const salaAtualizada = response.data;
    store.commit("Salas/atualizarSala", salaAtualizada);
    return salaAtualizada;
  }

  async deletarSala(sala) {
    const response = await Axios().delete(`salas/${sala.id}`);
    const salaDeletada = response.data;
    store.commit("Salas/deletarSala", sala.id);
    return salaDeletada;
  }

  async recuperarSala(sala) {
    return this.atualizarSala({ ...sala, deleted_at: null });
  }

  async cookpitSalas() {
    const response = await Axios().get("cookpit-salas");
    const salas = response.data.salas.map((sala) => new Sala(sala));
    store.commit("Salas/setCookpitSalas", salas);
    return salas;
  }

  async cookpitSalasLegenda() {
    const response = await Axios().get("cookpit-salas");
    const legendas = response.data.legenda.map((legenda) => new Legenda(legenda));
    store.commit("Salas/setCookpitSalasLegenda", legendas);
    return legendas;
  }
}
export default new SalasService();
