import Model from "./Model";

export const SituacaoHorarioType = {
  ATIVA: 1,
  DESATIVADA: 0,
};

export default class Horario extends Model {
  constructor(franquia) {
    super();
    Object.assign(this, franquia);
  }

  get ativa() {
    return this.situacao === SituacaoHorarioType.ATIVA;
  }

  get desativada() {
    return this.situacao === SituacaoHorarioType.DESATIVADA;
  }
}
