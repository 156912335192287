import Axios from "@/plugins/Axios";
import CategoriaSala from "@/Models/CategoriaSala";

export class CategoriaSalaService {
  async syncAll() {
    const response = await Axios().get("categoria-salas");
    const categorias = response.data.map((categoria) => new CategoriaSala(categoria));
    // store.commit("CategoriaSala/setCategoriaSala", categorias);
    return categorias;
  }

  async criarCategoriaSala(categoria) {
    const response = await Axios().post("categoria-salas", categoria);
    const novaCategoria = response.data;
    // store.commit("CategoriaSala/adicionarCategoriaSala", novaCategoria);
    return novaCategoria;
  }

  async atualizarCategoriaSala(categoria) {
    const response = await Axios().put(`categoria-salas/${categoria.id}`, categoria);
    const categoriaAtualizada = response.data;
    // store.commit("Salas/atualizarSala", categoriaAtualizada);
    return categoriaAtualizada;
  }

  async deletarCategoriaSala(categoria) {
    const response = await Axios().delete(`categoria-salas/${categoria.id}`);
    const categoriaDeletada = response.data;
    // store.commit("Categoria/deletarCategoriaSala", categoria.id);
    return categoriaDeletada;
  }

  // async recuperarSerie(serie) {
  //   return this.atualizarSerie({ ...serie, deleted_at: null });
  // }
}
export default new CategoriaSalaService();
