<template>
  <div>
    <div class="mb-5">
      <template>
        <v-dialog
          v-model="dialogEtapa"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
          :retain-focus="false"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark v-bind="attrs" v-on="on" @click="() => (editEtapa = null)">
              Cadastrar Nova Etapa
            </v-btn>
          </template>
          <v-card>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="dialogEtapa = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Etapas</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-list three-line subheader>
              <v-subheader>{{ editEtapa ? "Editando" : "Criando" }} Etapa</v-subheader>
              <div class="d-flex ma-5">
                <ValidationObserver v-slot="{ handleSubmit }">
                  <form @submit.prevent="handleSubmit(submitForm)" :disabled="submittingForm">
                    <v-row>
                      <v-col class="pt-0 pb-0" cols="6">
                        <ValidationProvider name="Descrição" rules="required" v-slot="{ errors }">
                          <e-label>Descrição</e-label>
                          <v-text-field
                            v-on:change="(value) => deixarLetraMinuscula(value, 'descricao')"
                            :error-messages="errors"
                            v-model="formEtapa.descricao"
                            dense
                            solo
                          />
                        </ValidationProvider>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pt-0 pb-0" cols="6">
                        <e-label>Período Inicial </e-label>
                        <v-dialog
                          ref="dialogInicial"
                          v-model="modalInicio"
                          :return-value.sync="data_inicial"
                          persistent
                          width="290px"
                          :retain-focus="false"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <ValidationProvider
                              name="Período Inicial"
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <v-text-field
                                v-model="formEtapa.periodo_inicial"
                                persistent-hint
                                label="Escolha uma data"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                dense
                                solo
                                :error-messages="errors"
                              ></v-text-field>
                            </ValidationProvider>
                          </template>
                          <v-date-picker v-model="data_inicial" scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="modalInicio = false">
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.dialogInicial.save(data_inicial)"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-dialog>
                      </v-col>
                      <v-col class="pt-0 pb-0" cols="6">
                        <e-label>Período Final </e-label>
                        <v-dialog
                          ref="dialogFinal"
                          v-model="modalFim"
                          :return-value.sync="data_final"
                          persistent
                          width="290px"
                          :retain-focus="false"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <ValidationProvider
                              name="Período Final"
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <v-text-field
                                v-model="formEtapa.periodo_final"
                                persistent-hint
                                label="Escolha uma data"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                dense
                                solo
                                :error-messages="errors"
                              ></v-text-field>
                            </ValidationProvider>
                          </template>
                          <v-date-picker v-model="data_final" scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="modalFim = false"> Cancel </v-btn>
                            <v-btn text color="primary" @click="$refs.dialogFinal.save(data_final)">
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-dialog>
                      </v-col>
                      <template v-if="editEtapa == 'Editando'">
                        <v-col class="pt-0 pb-0 mb-5" cols="12">
                          <v-divider></v-divider>
                          <h4 class="mt-3 mb-3">Regra</h4>
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="12">
                          <e-label>Expressão</e-label> <br />
                          <v-subheader>Monte a sua expressao clicando nos itens abaixo</v-subheader>
                          <div class="d-flex justify-space-around">
                            <div class="mr-3" style="width: 100%">
                              <v-text-field disabled v-model="expressao" dense solo />
                            </div>
                            <div class="">
                              <v-btn depressed color="error" @click="() => limpar()">
                                Limpar
                              </v-btn>
                            </div>
                          </div>
                          <e-expressao-dividers
                            type="subetapa"
                            :dados="subEtapasList"
                            label="Subetapas"
                            @printarSheet="($event) => printar($event)"
                          ></e-expressao-dividers>
                          <e-expressao-dividers
                            type="operacoes"
                            :dados="expressaoOperacoes"
                            label="Operações"
                            @printarSheet="($event) => printar($event)"
                          ></e-expressao-dividers>
                          <e-expressao-dividers
                            type="numerais"
                            :dados="expressaoNumerais"
                            label="Numerais"
                            @printarSheet="($event) => printar($event)"
                          ></e-expressao-dividers>
                        </v-col>
                        <v-col class="pt-0 pb-0 mt-5">
                          <e-label
                            >Selecione o Campo para mostrar o resultado da expressão:
                          </e-label>
                          <ValidationProvider
                            name="Circuito de Nota"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <e-autocomplete
                              :items="subEtapasList"
                              :return-object="false"
                              :item-text="
                                (subEt) => subEt.descricao + ' (' + subEt.etapa.descricao + ')'
                              "
                              :item-value="(subEt) => subEt.id"
                              :error-messages="errors"
                              v-model="id_subetapa_resultado"
                              label="Selecione uma opção"
                              dense
                              solo
                            />
                          </ValidationProvider>
                        </v-col>
                      </template>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-btn
                          color="primary"
                          :disabled="submittingForm"
                          :loading="submittingForm"
                          type="submit"
                          block
                          >Enviar Dados</v-btn
                        >
                      </v-col>
                    </v-row>
                  </form>
                </ValidationObserver>
              </div>
            </v-list>

            <v-list three-line subheader>
              <!-- <v-subheader>Regra</v-subheader> -->
            </v-list>
          </v-card>
        </v-dialog>
      </template>
    </div>
    <p v-if="etapasListByCircuito.length < 1" class="mt-5">
      Não existe nenhuma etapa cadastrada nesse circuito de notas!
    </p>
    <div v-else>
      <v-expansion-panels>
        <v-expansion-panel v-for="(item, i) in etapasListByCircuito" :key="i">
          <v-expansion-panel-header class="titleBimestre">
            <div class="d-flex justify-space-between">
              <div>
                <h3>{{ item.descricao }}</h3>
              </div>
              <div>
                <v-btn
                  x-small
                  class="ml-1"
                  color="blue-grey lighten-3"
                  @click="() => editarEtapa(item.id)"
                >
                  <v-icon small>fa fa-edit </v-icon>
                </v-btn>
                <v-btn
                  x-small
                  class="ml-1"
                  color="error"
                  v-on="on"
                  @click="() => deletarEtapa(item)"
                >
                  <v-icon small>fa fa-trash </v-icon>
                </v-btn>
              </div>
            </div>
          </v-expansion-panel-header>
          <v-divider></v-divider>
          <v-expansion-panel-content>
            <div class="mt-3">
              <div class="d-flex">
                <div class="mt-1">
                  <p>Regra dessa Etapa:</p>
                </div>
                <div class="ml-3">
                  <v-chip :color="item.regras[0] ? 'success' : 'error'" label>
                    {{ item.regras[0] ? item.regras[0].expressao : "Não cadastrada" }}
                  </v-chip>
                </div>
              </div>
            </div>
            <v-divider></v-divider>
            <div class="mt-3">
              <div class="d-flex justify-space-between">
                <div>
                  <h4>Subetapas</h4>
                </div>
                <div class="">
                  <template>
                    <v-dialog
                      v-model="dialogSub"
                      fullscreen
                      hide-overlay
                      transition="dialog-bottom-transition"
                      :retain-focus="false"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="mb-5"
                          color="primary"
                          dark
                          v-bind="attrs"
                          v-on="on"
                          @click="
                            () => {
                              setEtapaId(item.id);
                            }
                          "
                        >
                          Nova Subetapa
                        </v-btn>
                      </template>
                      <v-card>
                        <v-toolbar dark color="primary">
                          <v-btn icon dark @click="dialogSub = false">
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                          <v-toolbar-title>Subetapas</v-toolbar-title>
                          <v-spacer></v-spacer>
                        </v-toolbar>
                        <v-list three-line subheader>
                          <v-subheader>{{ editSub }} Nova Subetapa</v-subheader>
                          <div class="d-flex ma-5">
                            <ValidationObserver v-slot="{ handleSubmit }">
                              <form
                                @submit.prevent="handleSubmit(submitFormSub)"
                                :disabled="submittingForm"
                              >
                                <v-row>
                                  <v-col class="pt-0 pb-0" cols="4">
                                    <ValidationProvider
                                      name="Descrição"
                                      rules="required"
                                      v-slot="{ errors }"
                                    >
                                      <e-label>Descrição</e-label>
                                      <v-text-field
                                        v-on:change="
                                          (value) => deixarLetraMinuscula(value, 'descricao')
                                        "
                                        :error-messages="errors"
                                        v-model="formSub.descricao"
                                        dense
                                        solo
                                      />
                                    </ValidationProvider>
                                  </v-col>
                                  <v-col class="pt-0 pb-0">
                                    <ValidationProvider
                                      name="Tipo de Campo"
                                      rules="required"
                                      v-slot="{ errors }"
                                    >
                                      <e-label>Tipo de Campo</e-label>
                                      <e-autocomplete
                                        :items="$constants.subEtapaTipoCampos"
                                        :return-object="false"
                                        :item-value="(value) => value.slug"
                                        :item-text="(value) => value.descricao"
                                        :error-messages="errors"
                                        v-model="formSub.tipo_campo"
                                        label="Selecione uma opção"
                                        dense
                                        solo
                                      />
                                    </ValidationProvider>
                                  </v-col>
                                  <v-col class="pt-0 pb-0" cols="4">
                                    <ValidationProvider name="Valor Máximo" v-slot="{ errors }">
                                      <e-label>Valor Máximo (Padrão: 10,0)</e-label>
                                      <v-text-field
                                        v-on:change="
                                          (value) => deixarLetraMinuscula(value, 'valor_maximo')
                                        "
                                        :error-messages="errors"
                                        v-model="formSub.valor_maximo"
                                        dense
                                        solo
                                        type="number"
                                        step="0.2"
                                      />
                                    </ValidationProvider>
                                  </v-col>
                                  <v-col cols="12">
                                    <v-btn
                                      color="primary"
                                      :disabled="submittingForm"
                                      :loading="submittingForm"
                                      type="submit"
                                      block
                                      >Enviar Dados</v-btn
                                    >
                                  </v-col>
                                </v-row>
                              </form>
                            </ValidationObserver>
                          </div>
                        </v-list>
                      </v-card>
                    </v-dialog>
                  </template>
                </div>
              </div>
              <template>
                <p v-if="item.subetapas.length === 0">Nenhuma Subetapa cadastrada</p>
                <v-card v-else class="mx-auto" max-width="100%">
                  <v-list flat>
                    <v-list-item-group>
                      <v-list-item v-for="(sub, i) in item.subetapas" :key="i">
                        <v-list-item-content>
                          <v-list-item-title v-text="sub.descricao"></v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <div class="d-flex">
                            <v-btn
                              x-small
                              class="ml-1"
                              color="blue-grey lighten-3"
                              v-bind="attrs"
                              v-on="on"
                              @click="() => editarSub(sub.id, item)"
                            >
                              <v-icon small>fa fa-edit </v-icon>
                            </v-btn>
                            <v-btn
                              x-small
                              class="ml-1"
                              color="error"
                              v-on="on"
                              @click="() => deletarSubEtapa(sub)"
                            >
                              <v-icon small>fa fa-trash </v-icon>
                            </v-btn>
                          </div>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </template>
              <!-- <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Nome</th>
                        <th class="text-left">Tipo de Campo</th>
                        <th class="text-left">Peso</th>
                        <th class="text-left">Ações</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(sub, i) in item.subetapas" :key="i">
                        <td>
                          {{ sub.descricao }}
                        </td>
                        <td>
                          <v-btn
                            x-small
                            class="ml-1"
                            color="blue-grey lighten-3"
                            v-bind="attrs"
                            v-on="on"
                            @click="() => editarSub(sub.id)"
                          >
                            <v-icon small>fa fa-edit </v-icon>
                          </v-btn>
                          <v-btn
                            x-small
                            class="ml-1"
                            color="error"
                            v-on="on"
                            @click="() => deletarSubEtapa(sub)"
                          >
                            <v-icon small>fa fa-trash </v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template> -->
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import EExpressaoDividers from "./EExpressaoDividers.vue";

export default {
  components: { EExpressaoDividers },
  name: "expansion-etapas-list",
  props: {
    circuitoId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters("Etapas", ["etapas", "etapasByCircuito", "etapasListByCircuito"]),
    ...mapGetters("SubEtapas", ["subEtapas", "subEtapasList", "carregandoSubEtapas"]),
    formEtapa() {
      if (!this.etapasByCircuito || !this.editEtapa) return {};
      const etapa = this.etapasByCircuito.get(parseInt(this.etapaId, 10));
      if (etapa.regras.length > 0) {
        if (!this.editandoRegra) {
          this.loadRegra(Object.values(etapa.regras)[0]);
        }
      } else {
        this.clearCamposRegra();
      }
      return { ...etapa };
    },
    formSub() {
      if (!this.subEtapas || !this.editSub) return {};
      const sub = this.subEtapas.get(parseInt(this.subId, 10));

      return { ...sub };
    },
  },
  mounted() {
    this.loadEtapasByCircuito({ circuito_id: this.circuitoId });
    this.loadSubEtapasByCircuito({ circuito_nota_id: this.circuitoId });
    this.getSubEtapasListDoTipoResultado();
    // this.$store.dispatch("SubEtapas/loadSubEtapas");
  },
  data() {
    return {
      on: null,
      attrs: null,
      subEtapasListDoTipoResultado: [],
      circuito_id: this.circuitoId,
      data_inicial: null,
      data_final: null,
      modalInicio: false,
      modalFim: false,
      submittingForm: false,
      dialogEtapa: false,
      dialogSub: false,
      notifications: false,
      sound: true,
      widgets: false,
      etapaId: null,
      subId: null,
      editEtapa: null,
      editSub: null,
      id_subetapa_resultado: "",
      expressao: "",
      expressaoCodigo: "",
      camposResultadosByEtapa: this.subEtapas,
      subetapasSelecionadasForRegra: [],
      expressaoOperacoes: [
        {
          descricao: "+",
        },
        {
          descricao: "-",
        },
        {
          descricao: "*",
        },
        {
          descricao: "/",
        },
        {
          descricao: "(",
        },
        {
          descricao: ")",
        },
      ],
      expressaoNumerais: [
        {
          descricao: "1",
        },
        {
          descricao: "2",
        },
        {
          descricao: "3",
        },
        {
          descricao: "4",
        },
        {
          descricao: "5",
        },
        {
          descricao: "6",
        },
        {
          descricao: "7",
        },
        {
          descricao: "8",
        },
        {
          descricao: "9",
        },
        {
          descricao: "0",
        },
        {
          descricao: ",",
        },
      ],
      editandoRegra: false,
    };
  },
  watch: {
    data_inicial(value) {
      const data_old = new Intl.DateTimeFormat("pt-BR", { timeZone: "UTC" }).format(
        new Date(value)
      );
      this.formEtapa.periodo_inicial = data_old.replaceAll("/", "/");
    },
    data_final(value) {
      const data_old = new Intl.DateTimeFormat("pt-BR", { timeZone: "UTC" }).format(
        new Date(value)
      );
      this.formEtapa.periodo_final = data_old.replaceAll("/", "/");
    },
  },
  methods: {
    ...mapActions("Etapas", ["loadEtapasByCircuito"]),
    ...mapActions("SubEtapas", ["loadSubEtapasByCircuito"]),
    clearCamposRegra() {
      this.expressao = "";
      this.expressaoCodigo = "";
    },
    getSubEtapasListDoTipoResultado() {
      this.subEtapasListDoTipoResultado = this.subEtapas.filter(
        (sub) => sub.tipo_campo === "resultado" || sub.tipo_campo === "resultado_em_lote"
      );
    },
    loadRegra(regra) {
      this.expressao = regra.expressao === null ? "" : regra.expressao;
      this.id_subetapa_resultado = regra.id_subetapa_resultado;
      this.expressaoCodigo = regra.expressao_codigo === null ? "" : regra.expressao_codigo;
      this.subetapasSelecionadasForRegra =
        regra.subetapas === null ? [] : regra.subetapas.split(",");
    },
    async loadEtapas() {
      try {
        if (this.etapasByCircuito.length === 0) {
          this.$loaderService.open("Carregando dados");
          await Promise.all([this.loadEtapasByCircuito({ circuito_id: this.circuitoId })]);
          this.$loaderService.close();
        }
        this.$forceUpdate();
      } catch (error) {
        this.$handleError(error);
      }
    },
    async loadSubEtapas() {
      try {
        if (this.subEtapasListByCircuito.length === 0) {
          this.$loaderService.open("Carregando dados");
          await Promise.all([this.loadSubEtapasListByCircuito({ circuito_id: this.circuitoId })]);
          this.$loaderService.close();
        }
        this.$forceUpdate();
      } catch (error) {
        this.$handleError(error);
      }
    },
    deixarLetraMinuscula(value, campo) {
      const novo_valor = value.toLowerCase().split(" ");
      const valor = novo_valor
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
      this.form[campo] = valor;
    },
    editarEtapa(etapaId) {
      this.etapaId = etapaId;
      this.editEtapa = "Editando";
      this.dialogEtapa = true;
      // this.getSubEtapasListDoTipoResultado();

      const etapa = this.etapasByCircuito.get(parseInt(this.etapaId, 10));
      this.expressao = "oioi teste";
      if (etapa.regras.length > 0) {
        this.loadRegra(Object.values(etapa.regras)[0]);
      } else {
        this.clearCamposRegra();
      }
      // emitir evento
      this.$store.dispatch("SubEtapas/loadSubEtapasByCircuito", {
        circuito_nota_id: this.circuitoId,
      });
    },
    setEtapaId(etapaId) {
      this.etapaId = etapaId;
      this.editSub = null;
      this.formSub.descricao = "";
    },
    editarSub(subId, etapa) {
      this.setEtapaId(etapa.id);
      this.subId = subId;
      this.editSub = "Editando";
      this.dialogSub = true;
    },
    async submitForm() {
      this.submittingForm = true;
      this.formEtapa.circuito_nota_id = this.circuitoId;
      try {
        if (this.editEtapa) {
          this.formEtapa.expressao = this.expressao;
          this.formEtapa.expressao_codigo = this.expressaoCodigo;
          this.formEtapa.id_subetapa_resultado = this.id_subetapa_resultado;

          this.formEtapa.subetapas = this.subetapasSelecionadasForRegra.join(",");
          const array = this.formEtapa.subetapas.split(",");
          let arraySemVazios = array.filter((i) => i);

          arraySemVazios = arraySemVazios.join(",");
          this.formEtapa.subetapas = arraySemVazios;

          await this.$services.etapasService.atualizarEtapa(this.formEtapa);
        } else {
          await this.$services.etapasService.criarEtapa(this.formEtapa);
        }
        this.subetapasSelecionadasForRegra.length = 0;
        this.$store.dispatch("Etapas/loadEtapasByCircuito", { circuito_id: this.circuitoId });
        this.$toast.success("Etapa Atualizada com sucesso");
        this.dialogEtapa = false;
      } catch (error) {
        this.$handleError(error);
      }
      this.submittingForm = false;
    },
    async submitFormSub() {
      this.submittingForm = true;
      try {
        this.formSub.etapa_id = this.etapaId;
        this.formSub.circuito_nota_id = this.circuitoId;
        if (this.editSub) {
          await this.$services.subEtapasService.atualizarSubEtapa(this.formSub);
        } else {
          await this.$services.subEtapasService.criarSubEtapa(this.formSub);
        }
        this.$store.dispatch("Etapas/loadEtapasByCircuito", { circuito_id: this.circuitoId });
        this.$toast.success("SubEtapa Atualizada com sucesso");
        this.dialogSub = false;
      } catch (error) {
        this.$handleError(error);
      }
      this.submittingForm = false;
    },

    limpar() {
      this.expressao = "";
      this.expressaoCodigo = "";
      if (this.subetapasSelecionadasForRegra) {
        this.subetapasSelecionadasForRegra.length = 0;
      }
    },
    printar(event) {
      this.editandoRegra = true;

      this.expressao += event.descricao;

      if (event.type === "subetapa") {
        this.expressaoCodigo = `${this.expressaoCodigo}av[${event.id}]`;
      } else {
        this.expressaoCodigo += event.descricao;
      }

      if (!this.subetapasSelecionadasForRegra.includes(event.id)) {
        this.subetapasSelecionadasForRegra.push(event.id);
      }
    },
    async deletarEtapa(etapa) {
      this.$modals.danger({
        title: "Cuidado!",
        message: `Tem certeza que deseja apagar esta etapa?`,
        confirmationCode: etapa.id,
        confirmationMessage: `Por favor, digite <strong>${etapa.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Deletando a Etapa");
              try {
                await this.$services.etapasService.deletarEtapa(etapa);
                this.loadEtapasByCircuito({ circuito_id: this.circuitoId });
                this.$toast.success("Etapa deletada com sucesso");
                this.$forceUpdate();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              // text: true,
              small: true,
            },
          },
        ],
      });
    },
    async deletarSubEtapa(subetapa) {
      this.$modals.danger({
        title: "Cuidado!",
        message: `Tem certeza que deseja apagar esta subetapa?`,
        confirmationCode: subetapa.id,
        confirmationMessage: `Por favor, digite <strong>${subetapa.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Deletando a Subetapa");
              try {
                await this.$services.subEtapasService.deletarSubEtapa(subetapa);
                this.loadEtapasByCircuito({ circuito_id: this.circuitoId });
                this.$toast.success("Subetapa deletada com sucesso");
                this.$forceUpdate();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              // text: true,
              small: true,
            },
          },
        ],
      });
    },
  },
};
</script>
