<template>
  <div class="template-wrapper">
    <div>
      <e-left-menu />
    </div>

    <div class="main-content">
      <v-app-bar elevate-on-scroll width="100%" color="light" style="overflow: hidden">
        <v-btn icon @click="toggleMenu" color="black">
          <v-icon> mdi mdi-menu </v-icon>
        </v-btn>
        <!-- class="d-none d-sm-flex" -->
        <!-- <v-spacer /> -->
        <v-text-field
          dense
          label="Buscar"
          hide-details
          outlined
          append-icon="mdi-magnify"
        ></v-text-field>

        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" class="d-none d-sm-flex">
              <v-icon> mdi-table </v-icon>
            </v-btn>
          </template>

          <v-list three-line max-width="300px">
            <template v-for="(item, index) in items">
              <v-subheader v-if="item.header" :key="item.header" v-text="item.header"></v-subheader>

              <v-divider v-else-if="item.divider" :key="index" :inset="item.inset"></v-divider>

              <v-list-item v-else :key="item.title">
                <!-- <v-list-item-avatar>
                  <v-img :src="item.avatar"></v-img>
                </v-list-item-avatar> -->

                <v-list-item-content>
                  <v-list-item-title v-html="item.title"></v-list-item-title>
                  <v-list-item-subtitle v-html="item.subtitle"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>

        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon> mdi-bell-check-outline </v-icon>
            </v-btn>
          </template>

          <v-list three-line max-width="300px">
            <template v-for="(item, index) in items">
              <v-subheader v-if="item.header" :key="item.header" v-text="item.header"></v-subheader>

              <v-divider v-else-if="item.divider" :key="index" :inset="item.inset"></v-divider>

              <v-list-item v-else :key="item.title">
                <!-- <v-list-item-avatar>
                  <v-img :src="item.avatar"></v-img>
                </v-list-item-avatar> -->

                <v-list-item-content>
                  <v-list-item-title v-html="item.title"></v-list-item-title>
                  <v-list-item-subtitle v-html="item.subtitle"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>

        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon> mdi-message-alert-outline </v-icon>
            </v-btn>
          </template>

          <v-list three-line max-width="300px">
            <template v-for="(item, index) in items">
              <v-subheader v-if="item.header" :key="item.header" v-text="item.header"></v-subheader>

              <v-divider v-else-if="item.divider" :key="index" :inset="item.inset"></v-divider>

              <v-list-item v-else :key="item.title">
                <!-- <v-list-item-avatar>
                  <v-img :src="item.avatar"></v-img>
                </v-list-item-avatar> -->

                <v-list-item-content>
                  <v-list-item-title v-html="item.title"></v-list-item-title>
                  <v-list-item-subtitle v-html="item.subtitle"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>

        <v-divider class="mx-4" vertical></v-divider>

        <!-- <span class="text-subtitle-1 d-none d-sm-block" style="color: #212ad2" v-if="!hideFranquia">
          {{ user.franquia ? user.franquia.descricao : "----" }}
        </span> -->

        <!-- color="transparent" -->
        <v-list color="light" max-width="300px" sm="12">
          <v-menu offset-y sm="3">
            <template v-slot:activator="{ on, attrs }">
              <v-list-item v-bind="attrs" v-on="on">
                <v-list-item-avatar>
                  <!-- <e-user-avatar current /> -->
                  <v-avatar color="orange">
                    <v-icon dark> mdi-account-circle </v-icon>
                  </v-avatar>
                </v-list-item-avatar>

                <v-list-item-content>
                  <!-- <v-list-item-title class="white--black">
                    {{ (user ? user.name : "Carregando...") | truncate(15) }}
                  </v-list-item-title> -->

                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item-title v-bind="attrs" v-on="on">
                      {{ user.name ? user.name : "----" }}
                    </v-list-item-title>
                  </template>

                  <!-- <v-list-item-subtitle>
                    {{ user.franquia ? user.franquia.descricao : "----" }}
                  </v-list-item-subtitle> -->

                  <v-list-item-subtitle>
                    <!-- <e-user-role link /> -->
                    <span class="d-none d-sm-flex black--text font-weight-light">
                      {{ user.email ? user.email : "---" }}
                    </span>
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-icon>mdi-menu-down</v-icon>
                </v-list-item-action>
              </v-list-item>
            </template>

            <v-list dense>
              <v-list-item link @click="openSelecionarFranquiaModal = true">
                <v-list-item-avatar>
                  <v-icon small>fa fa-cogs</v-icon>
                </v-list-item-avatar>
                <v-list-item-title>Alterar unidade escolar</v-list-item-title>
              </v-list-item>
              <v-list-item dense link @click="openAtualizarSenhaModal = true">
                <v-list-item-avatar>
                  <v-icon small>fa fa-key</v-icon>
                </v-list-item-avatar>
                <v-list-item-title>Alterar senha</v-list-item-title>
              </v-list-item>
              <v-list-item dense link :to="{ name: 'logout' }">
                <v-list-item-avatar>
                  <v-icon small>fa fa-sign-out-alt</v-icon>
                </v-list-item-avatar>
                <v-list-item-title>Sair</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list>
      </v-app-bar>

      <v-row rols="12">
        <v-col class="d-flex justify-space-between align-center mt-3">
          <ul class="breadcrumb">
            <li><a href="/">Home</a></li>
            <li>
              <a href="#" style="color: gray"> {{ routeName }}</a>
            </li>
          </ul>

          <v-card-subtitle>
            Unidade | <span class="font-weight-black"> {{ user.franquia.descricao }} </span>
          </v-card-subtitle>
        </v-col>
      </v-row>

      <v-col>
        <slot />
      </v-col>

      <!-- <v-col style="height: 500px">
      </v-col> -->

      <!--
      <row>
        <v-col cols="12">
        </v-col>
      </row> -->

      <!-- <v-row>
        <v-breadcrumbs :items="breads"></v-breadcrumbs>
        <v-col>
          <h2>Painel de indicadores</h2>
        </v-col>
      </v-row> -->

      <!-- <v-container>
        <slot />
        <v-row style="min-height: 90px">
          <v-col>
            <span></span>
          </v-col>
        </v-row>
      </v-container>
      -->
    </div>
    <e-change-password-modal v-model="openAtualizarSenhaModal" />
    <e-selecionar-franquia-modal v-model="openSelecionarFranquiaModal" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EChangePasswordModal from "../../../components/EChangePasswordModal.vue";

const exibirFinanceiro = process.env.VUE_APP_ATIVAR_FINANCEIRO === "true";
export default {
  name: "MainTemplate",
  components: {
    EChangePasswordModal,
  },
  props: {
    hideFranquia: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters("Auth", ["user"]),
    ...mapGetters("Franquias", ["franquias"]),
    ...mapGetters("MainTemplate", ["menuOpen"]),
    routeName() {
      return this.$route.path;
    },
  },
  mounted() {
    this.$Tawk.$setOnLoadFunction(() => this.$Tawk.$showWidget());
  },
  data() {
    return {
      breads: [
        {
          text: "Home",
          disabled: false,
          href: "home",
        },
        {
          text: "Painel de indicadores",
          disabled: true,
          href: "home",
        },
      ],
      items: [
        { header: "Today" },
        {
          avatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
          title: "Brunch this weekend?",
          subtitle: `<span class="text--primary">Ali Connors</span> &mdash; I'll be in your neighborhood doing errands this weekend. Do you want to hang out?`,
        },
        { divider: true, inset: true },
        {
          avatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
          title: 'Summer BBQ <span class="grey--text text--lighten-1">4</span>',
          subtitle: `<span class="text--primary">to Alex, Scott, Jennifer</span> &mdash; Wish I could come, but I'm out of town this weekend.`,
        },
        { divider: true, inset: true },
        {
          avatar: "https://cdn.vuetifyjs.com/images/lists/3.jpg",
          title: "Oui oui",
          subtitle:
            '<span class="text--primary">Sandra Adams</span> &mdash; Do you have Paris recommendations? Have you ever been?',
        },
      ],
      selectedItem: 0,
      openAtualizarSenhaModal: false,
      openSelecionarFranquiaModal: false,
      exibirFinanceiro,
      menu: {
        items: [
          {
            icon: "fa fa-home",
            title: "Início",
            exibir: true,
            children: [{ title: "Ir para Início", to: { name: "home" }, icon: "" }],
          },
          {
            icon: "fa fa-user-graduate",
            title: "Matrículas",
            exibir: true,
            children: [
              { title: "Ver Matrículas", to: { name: "matriculas" }, icon: "" },
              // { title: "Adicionar matricula", to: "#", icon: "" },
              { title: "Ver Alunos", to: { name: "alunos" }, icon: "" },
              {
                title: "Pesquisa avançada",
                to: { name: "alunos-pesquisa-avancada" },
                icon: "",
              },
            ],
          },
          {
            icon: "fa fa-graduation-cap",
            title: "Turmas",
            exibir: true,
            children: [
              { title: "Ver turmas", to: { name: "turmas" }, icon: "" },
              // { title: "Adicionar turmas", to: "#", icon: "" },
              // { type: "spacer" },
              {
                title: "Gestão de séries",
                to: { name: "series" },
                icon: "fa fa-bookmark",
                permissions: ["series.*", "series.criar"],
              },
              {
                title: "Gestão de disciplinas",
                to: { name: "disciplinas" },
                icon: "fa fa-bookmark",
                permissions: ["disciplinas.*", "disciplinas.criarDisciplina"],
              },
              // { title: "Gestão de disciplinas", to: "#", icon: "" },
              // { title: "Gestão de cursos", to: "#", icon: "" },
            ],
          },
          {
            icon: "fa fa-chalkboard-teacher",
            title: "Diário online",
            exibir: true,
            children: [
              {
                title: "Listar todos",
                to: { name: "gestoesDeAulas" },
                icon: "",
                permissions: ["gestoesDeAulas.*", "gestoesDeAulas.criar"],
              },
            ],
          },
          {
            icon: "fa fa-users",
            title: "Usuários",
            exibir: true,
            children: [
              {
                title: "Ver Professores",
                to: { name: "professores" },
                icon: "fa fa-user-tie",
                permissions: ["users.*"],
              },
              {
                title: "Outros",
                to: { name: "usuarios" },
                icon: "fa fa-bookmark",
                permissions: ["users.*"],
              },
            ],
          },
          {
            icon: "fa fa-money",
            title: "Financeiro",
            exibir: exibirFinanceiro,
            children: [
              {
                title: "Mensalidades",
                to: { name: "mensalidades" },
                permissions: "financeiro.baixarParcela",
                icon: "",
              },
              {
                title: "Gestão de Caixas",
                to: { name: "caixas" },
                permissions: "caixas.abrir",
                icon: "",
              },
            ],
          },
          {
            icon: "mdi-account-network",
            title: "Administração",
            exibir: true,
            children: [
              {
                title: "Dashboard",
                to: { name: "admin" },
                icon: "",
              },
              {
                title: "Franquias",
                to: { name: "franquias" },
                permissions: ["franquias.*", "franquias.criarFranquia"],
                icon: "",
              },
              {
                title: "Salas",
                to: { name: "salas" },
                // permissions: ["salas.*", "salas.criarSala"],
                icon: "",
              },
              // {
              //   title: "Inep",
              //   to: { name: "inep" },
              //   icon: "",
              // },
            ],
          },
        ],
        width: "250px",
      },
    };
  },
  methods: {
    toggleMenu() {
      this.$store.commit("MainTemplate/setMenuOpen", !this.menuOpen);
    },
  },
};
</script>

<style scoped>
/* Style the list */
ul.breadcrumb {
  padding: 10px 16px;
  list-style: none;
  background-color: #eee;
}

/* Display list items side by side */
ul.breadcrumb li {
  display: inline;
  font-size: 15px;
}

/* Add a slash symbol (/) before/behind each list item */
ul.breadcrumb li + li:before {
  padding: 8px;
  color: black;
}

/* Add a color to all links inside the list */
ul.breadcrumb li a {
  color: #0275d8;
  text-decoration: none;
}

/* Add a color on mouse-over */
ul.breadcrumb li a:hover {
  color: #01447e;
  text-decoration: underline;
}
</style>
