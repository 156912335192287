import Axios from "@/plugins/Axios";
import store from "@/store";
import Circuito from "@/Models/Circuito";

export class CircuitoNotasService {
  async syncAll() {
    const currentUser = store.getters["Auth/user"];
    const response = await Axios().get(`circuito-notas?ano_id=${currentUser.ano_id}`);
    const circuitos = response.data.map((circuito) => new Circuito(circuito));
    store.commit("CircuitoNotas/setCircuitos", circuitos);
    return circuitos;
  }

  async syncAllGeral() {
    const response = await Axios().get("circuito-notas");
    const circuitos = response.data.map((circuito) => new Circuito(circuito));
    store.commit("CircuitoNotas/setCircuitosGeral", circuitos);
    return circuitos;
  }

  async importarCircuito(circuito_id) {
    const currentUser = store.getters["Auth/user"];
    const response = await Axios().get(
      `importar-circuito-notas?ano_id=${currentUser.ano_id}&circuito_id=${circuito_id}`
    );
    const circuitos = response.data.map((circuito) => new Circuito(circuito));
    store.commit("CircuitoNotas/setCircuitos", circuitos);
    return circuitos;
  }

  async criarCircuito(circuito) {
    const response = await Axios().post("circuito-notas", circuito);
    const novoCircuito = response.data;
    store.commit("CircuitoNotas/adicionarCircuito", novoCircuito);
    return novoCircuito;
  }

  async atualizarCircuito(circuito) {
    const response = await Axios().put(`circuito-notas/${circuito.id}`, circuito);
    const circuitoCircuito = response.data;
    store.commit("CircuitoNotas/atualizarCircuito", circuitoCircuito);
    return circuitoCircuito;
  }

  async deletarCircuito(circuito) {
    const response = await Axios().delete(`circuito-notas/${circuito.id}`);
    const circuitoDeletada = response.data;
    store.commit("CircuitoNotas/deletarCircuito", circuito.id);
    return circuitoDeletada;
  }
}
export default new CircuitoNotasService();
