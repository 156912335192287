import EtapasIndexPage from "@/Pages/Etapas/index.vue";
import NewEditEtapasPage from "@/Pages/Etapas/NewEditEtapasPage.vue";

export default [
  {
    path: "/etapas/",
    name: "etapas",
    component: EtapasIndexPage,
  },
  {
    path: "/etapas/create",
    name: "etapas.create",
    component: NewEditEtapasPage,
    props: { editing: false },
  },
  {
    path: "/etapas/edit/:etapa_id",
    name: "etapas.edit",
    component: NewEditEtapasPage,
    props: { editing: true },
  },
];
