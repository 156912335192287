import MapCollection from "collections/map";
import Vue from "vue";
import subcategoriaSalaService from "@/Services/SubcategoriaSalaService";
import $handleError from "@/plugins/handleError";

const state = () => ({
  subcategoriaSalas: [],
  carregandoSubcategoriaSalas: false,
  subcategoriaSalaLoaded: false,
  subcategoriaSalasList: [],
});
const mutations = {
  setSubcategoriaSala(state, subcategoriaSalas) {
    const subcategoriaSalasMap = new MapCollection();
    subcategoriaSalas.map((subcategoriaSala) =>
      subcategoriaSalasMap.set(subcategoriaSala.id, subcategoriaSala)
    );
    Vue.set(state, "subcategoriaSalas", subcategoriaSalasMap);
    Vue.set(state, "subcategoriaSalasList", Array.from(subcategoriaSalasMap.values()));
    Vue.set(state, "subcategoriaSalasLoaded", true);
    // state = {...state, salas: salasMap, salasLoaded: true}
  },
  setCarregandoSubcategoriaSalas(state, newValue) {
    state.carregandoSubcategoriaSalas = newValue;
  },
  adicionarSubcategoriaSala(state, categoria) {
    state.categoriaSalas.set(categoria.id, categoria);
    Vue.set(state, "categoriaSalas", state.categoriaSalas);
    Vue.set(state, "categoriaSalasList", Array.from(state.categoriaSalas.values()));
  },
  atualizarSubcategoriaSala(state, categoria) {
    state.categorias.set(categoria.id, categoria);
    Vue.set(state, "categorias", state.categorias);
    Vue.set(state, "categoriasList", Array.from(state.categorias.values()));
  },
  deletarSubcategoriaSala(state, categoriaId) {
    state.categorias.delete(categoriaId);
    Vue.set(state, "categorias", state.categorias);
    Vue.set(state, "categoriasList", Array.from(state.salas.values()));
  },
};
const actions = {
  async loadSubcategoriaSala({ commit }, options = {}) {
    commit("setCarregandoSubcategoriaSalas", true);
    try {
      const subcategoriaSalasList = await subcategoriaSalaService.syncAll(options.force);
      commit("setSubcategoriaSala", subcategoriaSalasList);
    } catch (error) {
      $handleError(error);
    }
    commit("setCarregandoSubcategoriaSalas", false);
  },
};
const getters = {
  subcategoriaSalas: (state) => state.subcategoriaSalas,
  carregandoSubcategoriaSalas: (state) => state.carregandosubcategoriaSalas,
  subcategoriaSalasLoaded: (state) => state.subcategoriaSalasLoaded,
  subcategoriaSalasList: (state) => state.subcategoriaSalasList,
};
const namespaced = true;
export default {
  state,
  mutations,
  actions,
  getters,
  namespaced,
};
