<template>
  <v-col cols="12" md="3">
    <v-card :loading="loading" class="mx-auto" height="180">
      <template slot="progress">
        <v-progress-linear color="indigo" indeterminate></v-progress-linear>
      </template>

      <v-card-text class="text-h6 font-weight-black"> Turmas </v-card-text>

      <v-row>
        <v-col>
          <v-card-text class="text-h4 font-weight-black">
            {{ qtd }}
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
  </v-col>
</template>

<script>
export default {
  name: "TurmasCard",
  mounted() {
    this.getQtd();
  },
  data() {
    return {
      loading: true,
      qtd: 0,
    };
  },
  methods: {
    async getQtd() {
      this.qtd = await this.$services.turmasService.qtd();
      this.loading = false;
    },
  },
};
</script>

<style scoped></style>
