const newTheme = {
  primary: "#eab23c",
  secondary: "#FB9716",
  accent: "#E86413",
  error: "#f44336",
  warning: "#ff9800",
  info: "#03a9f4",
  success: "#FB9716",
  anchor: "red",
  rufous: "#212AD2",
  blue: "#212AD2",
  light: "#fff",
};
export default newTheme;
