import SubEtapasIndexPage from "@/Pages/SubEtapas/index.vue";
import NewEditSubEtapasPage from "@/Pages/SubEtapas/NewEditSubEtapasPage.vue";

export default [
  {
    path: "/sub-etapas/",
    name: "sub-etapas",
    component: SubEtapasIndexPage,
  },
  {
    path: "/sub-etapas/create",
    name: "sub-etapas.create",
    component: NewEditSubEtapasPage,
    props: { editing: false },
  },
  {
    path: "/sub-etapas/edit/:sub_etapa_id",
    name: "sub-etapas.edit",
    component: NewEditSubEtapasPage,
    props: { editing: true },
  },
];
