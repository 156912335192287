import { render, staticRenderFns } from "./AulasLancadas.vue?vue&type=template&id=46c6c420&scoped=true&"
import script from "./AulasLancadas.vue?vue&type=script&lang=js&"
export * from "./AulasLancadas.vue?vue&type=script&lang=js&"
import style0 from "./AulasLancadas.vue?vue&type=style&index=0&id=46c6c420&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46c6c420",
  null
  
)

export default component.exports