<template>
  <div class="d-flex justify-space-between">
    <div>
      <strong>{{ quantidadePresentes }}%</strong>
    </div>
    <div>
      <v-progress-linear
        background-color="error"
        color="green"
        :value="quantidadePresentes"
        height="15"
        style="width: 15em"
        class="ma-1"
      ></v-progress-linear>
    </div>
    <div>
      <strong>{{ quantidadeAusentes }}%</strong>
    </div>
  </div>
</template>
<script>
export default {
  name: "barra-progresso",
  props: {
    porcentagemPresentes: {
      type: Number,
      required: true,
    },
    porcentagemAusentes: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      quantidadePresentes: this.porcentagemPresentes.toString(),
      quantidadeAusentes: this.porcentagemAusentes.toString(),
    };
  },
};
</script>
