<template>
  <main-template>
    <!-- <v-row>
      <v-col>
        <span class="text-h4">Turmas </span>
      </v-col>
    </v-row> -->
    <v-row>
      <v-col cols="12" class="pt-0 pb-0">
        <div class="d-flex mb-2 justify-space-between">
          <v-btn color="success" @click="criarTurma" class="mr-2">Criar Turma</v-btn>
          <v-text-field
            v-model="search"
            class="ml-2 search-input"
            append-icon="mdi-magnify"
            label="Pesquisar..."
            solo
            dense
            single-line
            hide-details
          />
        </div>
      </v-col>
      <v-col class="pt-0">
        <v-data-table
          :headers="table.headers"
          :items="turmasList"
          :loading="carregandoTurmas"
          :items-per-page="10"
          :custom-filter="dataTableFilter"
          :search="search"
          class="elevation-1"
        >
          <template v-slot:body="{ items }">
            <tbody name="scroll-x-transition" is="transition-group" duration="150">
              <tr
                color="primary"
                :class="turma.estaDeletado ? ['deleted'] : []"
                v-for="turma of items"
                :key="turma.id"
              >
                <td>{{ turma.id }}</td>
                <td>{{ turma.codigo }}</td>
                <td>
                  <v-btn text @click="() => visualizarTurma(turma)">
                    {{ turma.descricao }}
                  </v-btn>
                </td>
                <td>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip label v-bind="attrs" v-on="on" color="secondary" x-small> S </v-chip>
                    </template>
                    <span
                      v-html="
                        `Séries: ${turma.series.length} unidades <br>` +
                        turma.series.map((serie) => serie.descricao).join('<br />')
                      "
                    ></span>
                  </v-tooltip>
                  <v-tooltip v-if="!turma.estaDeletado" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        label
                        v-bind="attrs"
                        v-on="on"
                        color="secondary"
                        x-small
                        v-if="turma.multi"
                        class="ml-1"
                      >
                        M
                      </v-chip>
                    </template>
                    <span>DISCIPLINA MULTI</span>
                  </v-tooltip>
                </td>
                <td class="text-center">{{ turma.matriculas_count }}/{{ turma.qtd }}</td>
                <td>
                  <!-- para nao exibir a turma se estivar fechada adicione  no v-if & !turma.turma_fechada -->
                  <v-tooltip v-if="!turma.estaDeletado" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="blue-grey lighten-3"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon small>fa fa-calendar-o</v-icon>
                      </v-btn>
                    </template>
                    <span>Horário</span>
                  </v-tooltip>
                  <v-tooltip v-if="!turma.estaDeletado" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="blue-grey lighten-3"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => editarTurma(turma)"
                      >
                        <v-icon small>fa fa-edit </v-icon>
                      </v-btn>
                    </template>
                    <span>Editar turma</span>
                  </v-tooltip>

                  <v-tooltip v-if="!turma.estaDeletado" color="error" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="error"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => deletarTurma(turma)"
                      >
                        <v-icon small>fa fa-trash </v-icon>
                      </v-btn>
                    </template>
                    <span>Apagar turma</span>
                  </v-tooltip>

                  <v-tooltip v-if="turma.estaDeletado" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="info"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => recuperarTurma(turma)"
                      >
                        <v-icon small>fa fa-sync </v-icon>
                      </v-btn>
                    </template>
                    <span>Recuperar turma</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <e-selecionar-gestao-modal
      :dialog="dialog"
      :turma_id="turma_id"
      @dialogChange="dialog = $event"
    ></e-selecionar-gestao-modal>
  </main-template>
</template>

<script>
// import { mapGetters } from "vuex";
import { dataTableFilter } from "@/plugins/searchQuery";

export default {
  computed: {
    // ...mapGetters("Turmas", ["turmasList", "carregandoTurmas"]),
  },
  data() {
    return {
      dialog: false,
      dataTableFilter,
      search: "",
      turma_id: null,
      turmasList: [],
      carregandoTurmas: true,
      table: {
        headers: [
          { text: "#", value: "id" },
          { text: "Código", value: "codigo" },
          { text: "Nome", value: "descricao" },
          { text: "Opções", value: "multi", sortable: false },
          { text: "Quantidade", value: "quantidade", sortable: false },
          { text: "Ações", value: "actions", sortable: false },
        ],
      },
    };
  },
  mounted() {
    // this.$store.dispatch("Turmas/loadTurmas");
    this.load();
  },
  watch: {
    carregandoTurmas(value) {
      if (value && this.turmasList.length === 0) {
        this.$loaderService.open("Carregando turmas");
      } else {
        this.$loaderService.close();
      }
    },
  },
  methods: {
    async load() {
      this.turmasList = await this.$services.turmasService.syncAll();
      this.carregandoTurmas = false;
    },
    criarTurma() {
      this.$router.push({ name: "turmas.create" });
    },
    abrirTurma(turma) {
      this.$modals.danger({
        title: "Cuidado!",
        message: "Tem certeza que deseja abrir esta turma?",
        confirmationCode: turma.id,
        confirmationMessage: `Por favor, digite <strong>${turma.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Abrindo a Turma");
              try {
                await this.$services.turmasService.abrirTurma(turma);
                this.$toast.success("Turma aberta com sucesso");
                this.$forceUpdate();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
              this.$store.dispatch("Turmas/loadTurmas");
            },
            props: {
              color: "error",
              // text: true,
              small: true,
            },
          },
        ],
      });
    },
    visualizarTurma(turma) {
      if (parseInt(turma.curso_id, 10) === 10) {
        this.turma_id = turma.id;
        this.dialog = !this.dialog;
        return;
      }
      this.$router.push({ name: "turmas.view", params: { turma_id: turma.id } });
    },
    editarTurma(turma) {
      this.$router.push({ name: "turmas.edit", params: { turma_id: turma.id } });
      // return turma;
    },
    async deletarTurma(turma) {
      this.$modals.danger({
        title: "Cuidado!",
        message: "Tem certeza que deseja apagar esta turma?",
        confirmationCode: turma.id,
        confirmationMessage: `Por favor, digite <strong>${turma.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Deletando a Turma");
              try {
                await this.$services.turmasService.deletarTurma(turma);
                this.$toast.success("Turma deletada com sucesso");
                this.$forceUpdate();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              // text: true,
              small: true,
            },
          },
        ],
      });
    },
    recuperarTurma(turma) {
      this.$modals.warn({
        title: "Atenção!",
        message: "Tem certeza que deseja recuperar esta turma?",
        confirmationCode: turma.id,
        confirmationMessage: `Por favor, digite <strong>${turma.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Recuperando a Turma");
              try {
                await this.$services.turmasService.recuperarTurma(turma);
                this.$toast.success("Turma recuperada com sucesso");
                this.$forceUpdate();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              // text: true,
              small: true,
            },
          },
        ],
      });
    },
  },
};
</script>

<style></style>
