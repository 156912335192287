import Vue from "vue";
import AxiosPlugin from "vue-axios-cors";
import toast from "vuetify-snackbar-toast";
import VueFilterDateFormat from "@vuejs-community/vue-filter-date-format";
import VueFilterDateParse from "@vuejs-community/vue-filter-date-parse";
import Tawk from "vue-tawk";
import EModalsDinamic from "@/plugins/EModalsDinamic";
import { VueMaskDirective } from "v-mask";
import VueTheMask from "vue-the-mask";
import EModals from "@/plugins/EModals";
import ELoaderService from "@/plugins/ELoaderService";
import "@/plugins/apexcharts";

import "@/plugins/filters";
import { handleErrorPlugin } from "@/plugins/handleError";
import constants from "@/plugins/constants";
import "@/plugins/currencyField";
import "@/plugins/veeValidate";
import can from "@/plugins/can";
import services from "@/plugins/services";
import "./plugins/vue2Filters";
import Pusher from "pusher-js";
import VuePusher from "vue-pusher";
import App from "./App.vue";
import "./app.css";
import "animate.css/animate.min.css";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./plugins/components";

Pusher.logToConsole = false;
Vue.use(VueTheMask);
Vue.use(constants);
Vue.directive("mask", VueMaskDirective);
Vue.use(Tawk, {
  tawkSrc: process.env.VUE_APP_TAWK_TO_SRC,
});
Vue.use(EModals);
Vue.use(EModalsDinamic);
Vue.use(ELoaderService);
Vue.use(AxiosPlugin);
Vue.use(toast);
Vue.use(VueFilterDateFormat);
Vue.use(VueFilterDateParse);
Vue.use(can);
Vue.use(services);
Vue.use(handleErrorPlugin);
Vue.config.productionTip = false;

Vue.use(VuePusher, {
  api_key: "765aad81442de333eed6",
  options: {
    cluster: "mt1",
  },
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
