<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="() => $router.push({ name: 'etapas' })"
            :disabled="submittingForm"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          {{ editing ? "Editando" : "Criando" }} Etapa
        </h1>
      </v-col>
    </v-row>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submitForm)" :disabled="submittingForm">
        <v-row>
          <v-col class="pt-0 pb-0" cols="6">
            <ValidationProvider name="Descrição" rules="required" v-slot="{ errors }">
              <e-label>Descrição</e-label>
              <v-text-field
                v-on:change="(value) => deixarLetraMinuscula(value, 'descricao')"
                :error-messages="errors"
                v-model="form.descricao"
                dense
                solo
              />
            </ValidationProvider>
          </v-col>
          <v-col class="pt-0 pb-0" cols="6">
            <ValidationProvider name="Circuito de Nota" rules="required" v-slot="{ errors }">
              <e-label>Circuito de Nota</e-label>
              <e-autocomplete
                :items="circuitosList"
                :return-object="false"
                :item-text="(circuito) => circuito.descricao"
                :item-value="(circuito) => circuito.id"
                :error-messages="errors"
                v-model="form.circuito_nota_id"
                label="Selecione uma opção"
                dense
                solo
              />
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0 pb-0" cols="6">
            <e-label>Período Inicial </e-label>
            <v-dialog
              ref="dialogInicial"
              v-model="modalInicio"
              :return-value.sync="data_inicial"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="form.periodo_inicial"
                  persistent-hint
                  label="Escolha uma data"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  dense
                  solo
                ></v-text-field>
              </template>
              <v-date-picker v-model="data_inicial" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modalInicio = false"> Cancel </v-btn>
                <v-btn text color="primary" @click="$refs.dialogInicial.save(data_inicial)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col class="pt-0 pb-0" cols="6">
            <e-label>Período Final </e-label>
            <v-dialog
              ref="dialogFinal"
              v-model="modalFim"
              :return-value.sync="data_final"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="form.periodo_final"
                  persistent-hint
                  label="Escolha uma data"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  dense
                  solo
                ></v-text-field>
              </template>
              <v-date-picker v-model="data_final" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modalFim = false"> Cancel </v-btn>
                <v-btn text color="primary" @click="$refs.dialogFinal.save(data_final)"> OK </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-btn
              color="primary"
              :disabled="submittingForm"
              :loading="submittingForm"
              type="submit"
              block
              >Enviar Dados</v-btn
            >
          </v-col>
        </v-row>
      </form>
    </ValidationObserver>
  </main-template>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ELabel from "../../components/ELabel.vue";

export default {
  components: { ELabel },
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters("Etapas", ["etapas"]),
    ...mapGetters("CircuitoNotas", ["circuitos", "circuitosList", "carregandoCircuitoNotas"]),
    form() {
      if (!this.etapas || !this.editing) return {};
      const { etapa_id } = this.$route.params;
      const etapa = this.etapas.get(parseInt(etapa_id, 10));
      return { ...etapa };
    },
  },
  mounted() {
    this.loadData();
  },
  data() {
    return {
      data_inicial: null,
      data_final: null,
      modalInicio: false,
      modalFim: false,
      submittingForm: false,
    };
  },
  watch: {
    data_inicial(value) {
      const data_old = new Intl.DateTimeFormat("pt-BR", { timeZone: "UTC" }).format(
        new Date(value)
      );
      this.form.periodo_inicial = data_old.replaceAll("/", "/");
    },
    data_final(value) {
      const data_old = new Intl.DateTimeFormat("pt-BR", { timeZone: "UTC" }).format(
        new Date(value)
      );
      this.form.periodo_final = data_old.replaceAll("/", "/");
    },
  },
  methods: {
    ...mapActions("Etapas", ["loadEtapas"]),
    ...mapActions("CircuitoNotas", ["loadCircuitos"]),
    deixarLetraMinuscula(value, campo) {
      const novo_valor = value.toLowerCase().split(" ");
      const valor = novo_valor
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
      this.form[campo] = valor;
    },
    async submitForm() {
      this.submittingForm = true;
      try {
        if (this.editing) {
          await this.$services.etapasService.atualizarEtapa(this.form);
        } else {
          await this.$services.etapasService.criarEtapa(this.form);
        }
        this.$toast.success("Etapa Atualizada com sucesso");
        this.$router.push({ name: "etapas" });
      } catch (error) {
        this.$handleError(error);
      }
      this.submittingForm = false;
    },
    async loadData() {
      try {
        if (this.etapas.length === 0) {
          this.$loaderService.open("Carregando dados");
          await Promise.all([this.loadEtapas()]);
          await Promise.all([this.loadCircuitos()]);
          this.$loaderService.close();
        }
        this.$forceUpdate();
      } catch (error) {
        this.$handleError(error);
      }
    },
  },
};
</script>

<style></style>
