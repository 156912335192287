import MatriculasIndexPage from "@/Pages/Matriculas/index.vue";
import NewEditMatriculaPage from "@/Pages/Matriculas/NewEditMatriculaPage.vue";

export default [
  {
    path: "/matriculas/",
    name: "matriculas",
    component: MatriculasIndexPage,
  },
  {
    path: "/matriculas/create",
    name: "matriculas.create",
    component: NewEditMatriculaPage,
    props: { editing: false },
  },
  {
    path: "/matriculas/edit/:matricula_id",
    name: "matriculas.edit",
    component: NewEditMatriculaPage,
    props: { editing: true },
  },
];
