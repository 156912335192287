<template>
  <v-card :color="color" class="mb-3 mx-1 card-sala" max-width="250" outlined>
    <div class="text-overline text-center turma">{{ titleCard }}</div>
    <v-list-item three-line>
      <v-list-item-content class="pt-0 pb-0">
        <div class="d-flex">
          <p>Biologia</p>
          <v-list-item-avatar class="mr-1 mr-lg-1 mt-0" color="grey darken-3" size="20">
            <v-img
              class="elevation-6"
              alt=""
              src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light"
            ></v-img>
          </v-list-item-avatar>

          <p class="">Carlos Daniel</p>
        </div>
        <div class="d-flex">
          <div>
            <span class="material-icons draw-icon"> arrow_drop_up </span>
          </div>
          <div>
            <p>60% 34/40 alunos</p>
          </div>
        </div>
      </v-list-item-content>
      <span class="material-icons mr-1 check-icon"> task_alt </span>
      <v-btn fab light large>
        <v-list-item-title color="#99FF99" class="text-h5 mb-1 text-sala">
          {{ salaTitle }}
        </v-list-item-title>
      </v-btn>
    </v-list-item>
  </v-card>
</template>

<script>
export default {
  name: "card-sala",
  props: {
    dadosSalas: {
      type: Object,
      required: true,
    },
    i: {
      type: Number,
      required: true,
    },
  },
  computed: {
    color() {
      switch (this.dadosSalas.status) {
        case "ocupada":
          return "blue";

        case "livre":
          return "green";

        case "desocupando":
          return "#00FFFF";

        case "manutencao":
          return "#FFCC00";

        case "interditada":
          return "red";

        default:
          return "#9e9e9e";
      }
    },
  },
  data() {
    return {
      salaTitle: this.dadosSalas.description,
      titleCard:
        this.dadosSalas.turmas.length > 0
          ? Object.values(this.dadosSalas.turmas)[0].descricao
          : this.dadosSalas.subcategoria_de_sala.description,
      // disciplina: this.dadosSalas.disciplina,
      // professor: this.dadosSalas.professor,
      // porcentagemAlunosPresentes: this.dadosSalas.porcentagemAlunosPresentes,
      // quantidadeAlunosPresentes: this.dadosSalas.quantidadeAlunosPresentes,
      // totalAlunosTurma: this.dadosSalas.totalAlunosTurma,
      // aulasVerificadas: this.dadosSalas.aulasVerificadas,
      // totalDeAulas: this.dadosSalas.totalDeAulas,
      status: this.dadosSalas.status,
    };
  },
};
</script>

<style>
.card-sala .turma {
  color: #fff;
}

.card-sala p {
  font-size: 10px;
  color: #fff;
  border: 10px;
  font-weight: bold;
  padding-top: 5px;
}
.card-sala .draw-icon {
  color: #fff;
}
.card-sala .check-icon {
  color: #fff;
}
.card-sala .text-sala {
  font-size: 10px;
  font-weight: bold;
}
</style>
