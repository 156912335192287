import SalaIndexPage from "@/Pages/Salas/index.vue";
import NewEditSalaPage from "@/Pages/Salas/NewEditSalaPage.vue";

export default [
  {
    path: "/salas/",
    name: "salas",
    component: SalaIndexPage,
  },
  {
    path: "/salas/create",
    name: "salas.create",
    component: NewEditSalaPage,
    props: { editing: false },
  },
  {
    path: "/salas/edit/:sala_id",
    name: "salas.edit",
    component: NewEditSalaPage,
    props: { editing: true },
  },
];
