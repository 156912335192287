<template>
  <v-expansion-panels accordion>
    <v-expansion-panel v-for="(item, i) in 1" :key="i" class="mt-2">
      <v-expansion-panel-header>{{ label }}</v-expansion-panel-header>
      <v-expansion-panel-content>
        <div v-if="type === 'subetapa'">
          <v-btn
            v-for="(item, i) in subEtapasList"
            :key="i"
            class="ma-2"
            outlined
            color="indigo"
            @click="() => printarSheet(item, 'subetapa')"
          >
            {{ item.descricao }} ({{ item.etapa.descricao }})
          </v-btn>
        </div>
        <v-btn
          v-else
          v-for="(item, i) in dadosButtons"
          :key="i"
          class="ma-2"
          outlined
          color="indigo"
          @click="() => printarSheet(item)"
        >
          {{ item.descricao }}
        </v-btn>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "expressao-dividers",
  props: {
    dados: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
    },
    label: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters("SubEtapas", ["subEtapas", "subEtapasList", "carregandoSubEtapas"]),
  },
  data() {
    return {
      subEtapasSelecionadas: [],
      typeDado: this.type,
      dadosButtons: this.dados.filter((d) => d != null),
    };
  },
  methods: {
    // regras
    printarSheet(item, type) {
      item.type = type;
      this.$emit("printarSheet", item);
    },
  },
};
</script>
