<template>
  <main-template>
    <v-container>
      <div class="d-flex justify-space-between mb-6 flex-column flex-lg-row flex-sm-column">
        <div class="d-flex justify-space-between mb-6 flex-column flex-lg-row flex-sm-column">
          <div v-for="(status, i) in salasCookpitLegendaList" :key="status.title">
            <e-legenda
              class="d-flex justify-space-between justify-sm-space-between mb-3 mb-lg-0"
              :status="status"
              :i="i"
            />
          </div>
        </div>
        <div>
          <e-quantidade :presentes="dadosAlunos.qtdPresentes" :ausentes="dadosAlunos.qtdAusentes" />
        </div>
        <e-barra-progresso
          :porcentagemPresentes="dadosAlunos.porcentagemPresentes"
          :porcentagemAusentes="dadosAlunos.porcentagemAusentes"
        />
      </div>
      <v-row
        class="d-flex justify-space-between mb-6 mt-10 mt-lg-0 flex-column flex-lg-row flex-sm-column"
      >
        <div v-for="(sala, i) in salasCookpitList" :key="sala.id">
          <e-card-sala :dadosSalas="sala" :i="i" />
        </div>
      </v-row>
      <div v-if="salasCookpitList.length > 12" class="text-center">
        <v-btn rounded color="success" dark> Ver Todas </v-btn>
      </div>
    </v-container>
  </main-template>
</template>

<script>
import { mapGetters } from "vuex";
import Pusher from "pusher-js";
import EBarraProgresso from "../../components/Cookpit/EBarraProgresso.vue";
import ECardSala from "../../components/Cookpit/ECardSala.vue";
import EQuantidade from "../../components/Cookpit/EQuantidade.vue";

export default {
  components: { EQuantidade, EBarraProgresso, ECardSala },
  computed: {
    ...mapGetters("Salas", ["salasCookpitList", "salasCookpitLegendaList", "carregandoSalas"]),
  },
  mounted() {
    const pusher = new Pusher("765aad81442de333eed6", {
      cluster: "mt1",
    });

    const channel = pusher.subscribe("salas");
    channel.bind("App\\Events\\SalaStatusUpdate", () => {
      this.$store.dispatch("Salas/loadCookpitSalas");
      this.$store.dispatch("Salas/loadCookpitSalasLegenda");
    });
    this.$store.dispatch("Salas/loadCookpitSalas");
    this.$store.dispatch("Salas/loadCookpitSalasLegenda");
  },
  watch: {
    carregandoSalas(value) {
      if (value && this.salasCookpitList.length === 0) {
        this.$loaderService.open("Carregando Cookpi de salas");
      } else {
        this.$loaderService.close();
      }
    },
  },
  data() {
    return {
      messages: [],
      itens: [
        {
          title: "Livre",
          color: "green",
          quant: 2,
        },
        {
          title: "Ocupada",
          color: "blue",
          quant: 9,
        },
        {
          title: "Desocupando",
          color: "#00FFFF",
          quant: 7,
        },
        {
          title: "Manutenção",
          color: "#FFCC00",
          quant: 4,
        },
        {
          title: "Interditada",
          color: "red",
          quant: 3,
        },
      ],
      dadosAlunos: {
        qtdPresentes: 10,
        qtdAusentes: 8,
        porcentagemPresentes: 30,
        porcentagemAusentes: 70,
      },
      dadosSalas: [
        {
          title: "B5",
          turma: "TIF2021",
          disciplina: "Biologia",
          professor: "Carlos Daniel",
          porcentagemAlunosPresentes: 60,
          quantidadeAlunosPresentes: 34,
          totalAlunosTurma: 40,
          aulasVerificadas: 10,
          totalDeAulas: 12,
          status: "ocupada",
        },
        {
          title: "B7",
          turma: "TIF2021",
          disciplina: "Matemática",
          professor: "Pedro Santos",
          porcentagemAlunosPresentes: 60,
          quantidadeAlunosPresentes: 34,
          totalAlunosTurma: 40,
          aulasVerificadas: 10,
          totalDeAulas: 12,
          status: "livre",
        },
        {
          title: "C7",
          turma: "TIF2021",
          disciplina: "Matemática",
          professor: "Pedro Santos",
          porcentagemAlunosPresentes: 60,
          quantidadeAlunosPresentes: 34,
          totalAlunosTurma: 40,
          aulasVerificadas: 10,
          totalDeAulas: 12,
          status: "desocupando",
        },
        {
          title: "F7",
          turma: "TIF2021",
          disciplina: "Matemática",
          professor: "Pedro Santos",
          porcentagemAlunosPresentes: 60,
          quantidadeAlunosPresentes: 34,
          totalAlunosTurma: 40,
          aulasVerificadas: 10,
          totalDeAulas: 12,
          status: "manutencao",
        },
        {
          title: "A7",
          turma: "TIF2021",
          disciplina: "Matemática",
          professor: "Pedro Santos",
          porcentagemAlunosPresentes: 60,
          quantidadeAlunosPresentes: 34,
          totalAlunosTurma: 40,
          aulasVerificadas: 10,
          totalDeAulas: 12,
          status: "interditada",
        },
        {
          title: "B3",
          turma: "TIF2021",
          disciplina: "Matemática",
          professor: "Pedro Santos",
          porcentagemAlunosPresentes: 60,
          quantidadeAlunosPresentes: 34,
          totalAlunosTurma: 40,
          aulasVerificadas: 10,
          totalDeAulas: 12,
          status: "",
        },
        {
          title: "B9",
          turma: "TIF2021",
          disciplina: "Matemática",
          professor: "Pedro Santos",
          porcentagemAlunosPresentes: 60,
          quantidadeAlunosPresentes: 34,
          totalAlunosTurma: 40,
          aulasVerificadas: 10,
          totalDeAulas: 12,
          status: "",
        },
        {
          title: "B2",
          turma: "TIF2021",
          disciplina: "Matemática",
          professor: "Pedro Santos",
          porcentagemAlunosPresentes: 60,
          quantidadeAlunosPresentes: 34,
          totalAlunosTurma: 40,
          aulasVerificadas: 10,
          totalDeAulas: 12,
          status: "livre",
        },
        {
          title: "B2",
          turma: "TIF2021",
          disciplina: "Matemática",
          professor: "Pedro Santos",
          porcentagemAlunosPresentes: 60,
          quantidadeAlunosPresentes: 34,
          totalAlunosTurma: 40,
          aulasVerificadas: 10,
          totalDeAulas: 12,
          status: "",
        },
        {
          title: "B2",
          turma: "TIF2021",
          disciplina: "Matemática",
          professor: "Pedro Santos",
          porcentagemAlunosPresentes: 60,
          quantidadeAlunosPresentes: 34,
          totalAlunosTurma: 40,
          aulasVerificadas: 10,
          totalDeAulas: 12,
          status: "",
        },
        {
          title: "B2",
          turma: "TIF2021",
          disciplina: "Matemática",
          professor: "Pedro Santos",
          porcentagemAlunosPresentes: 60,
          quantidadeAlunosPresentes: 34,
          totalAlunosTurma: 40,
          aulasVerificadas: 10,
          totalDeAulas: 12,
          status: "",
        },
        {
          title: "B2",
          turma: "TIF2021",
          disciplina: "Matemática",
          professor: "Pedro Santos",
          porcentagemAlunosPresentes: 60,
          quantidadeAlunosPresentes: 34,
          totalAlunosTurma: 40,
          aulasVerificadas: 10,
          totalDeAulas: 12,
          status: "livre",
        },
      ],
    };
  },
};
</script>
