import MapCollection from "collections/map";
import Vue from "vue";
import horariosService from "@/Services/HorariosService";
import $handleError from "@/plugins/handleError";

const state = () => ({
  horarios: [],
  carregandoHorarios: false,
  horariosLoaded: false,
  horariosList: [],
});
const mutations = {
  setHorarios(state, horarios) {
    const horariosMap = new MapCollection();
    horarios.map((serie) => horariosMap.set(serie.id, serie));
    Vue.set(state, "horarios", horariosMap);
    Vue.set(state, "horariosList", Array.from(horariosMap.values()));
    Vue.set(state, "horariosLoaded", true);
    // state = {...state, series: seriesMap, horariosLoaded: true}
  },
  setCarregandoHorarios(state, newValue) {
    state.carregandoHorarios = newValue;
  },
  adicionarHorario(state, horario) {
    state.horarios.set(horario.id, horario);
    Vue.set(state, "horarios", state.horarios);
    Vue.set(state, "horariosList", Array.from(state.horarios.values()));
  },
  atualizarHorario(state, horario) {
    state.horarios.set(horario.id, horario);
    Vue.set(state, "horarios", state.horarios);
    Vue.set(state, "horariosList", Array.from(state.horarios.values()));
  },
  deletarSerie(state, horarioId) {
    state.horarios.delete(horarioId);
    Vue.set(state, "horarios", state.horarios);
    Vue.set(state, "horariosList", Array.from(state.horarios.values()));
  },
};
const actions = {
  async loadHorarios({ commit }, options = {}) {
    commit("setCarregandoHorarios", true);
    try {
      const horariosList = await horariosService.syncAll(options.force);
      commit("setHorarios", horariosList);
    } catch (error) {
      $handleError(error);
    }
    commit("setCarregandoHorarios", false);
  },
};
const getters = {
  horarios: (state) => state.horarios,
  carregandoHorarios: (state) => state.carregandoHorarios,
  horariosLoaded: (state) => state.horariosLoaded,
  horariosList: (state) => state.horariosList,
};
const namespaced = true;
export default {
  state,
  mutations,
  actions,
  getters,
  namespaced,
};
