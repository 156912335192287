import Axios from "@/plugins/Axios";
import store from "@/store";
import Horario from "@/Models/Horario";

export class HorariosService {
  async syncAll() {
    const response = await Axios().get("horarios");
    const horarios = response.data.map((horario) => new Horario(horario));
    store.commit("Horarios/setHorario", horarios);
    return horarios;
  }

  async criarHorario(horario) {
    const response = await Axios().post("horarios", horario);
    const novoHorario = response.data;
    store.commit("Horarios/adicionarHorario", novoHorario);
    return novoHorario;
  }

  async atualizarHorario(horario) {
    const response = await Axios().put(`Horarios/${horario.id}`, horario);
    const serieHorario = response.data;
    store.commit("Horarios/atualizarHorario", serieHorario);
    return serieHorario;
  }

  async deletarHorario(horario) {
    const response = await Axios().delete(`Horarios/${horario.id}`);
    const horarioDeletada = response.data;
    // store.commit("Horarios/deletarHorario",  serie.id);
    return horarioDeletada;
  }

  async recuperarHorario(horario) {
    return this.atualizarHorario({ ...horario, deleted_at: null });
  }
}
export default new HorariosService();
