<template>
  <main-template>
    <v-row>
      <v-col>
        <span class="text-h4">Salas </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pt-0 pb-0">
        <div class="d-flex mb-2 justify-space-between">
          <v-btn color="success" @click="criarSala" class="mr-2">Criar Sala</v-btn>
          <v-text-field
            v-model="search"
            class="ml-2 search-input"
            append-icon="mdi-magnify"
            label="Pesquisar..."
            solo
            autofocus
            dense
            single-line
            hide-details
          />
        </div>
      </v-col>
      <v-col class="pt-0">
        <v-data-table
          :headers="table.headers"
          :items="salasList"
          :loading="carregandoSalas"
          :items-per-page="10"
          :custom-filter="dataTableFilter"
          :search="search"
          class="elevation-1"
        >
          <template v-slot:body="{ items }">
            <tbody name="scroll-x-transition" is="transition-group" duration="150">
              <tr
                color="primary"
                :class="sala.estaDeletado ? ['deleted'] : []"
                v-for="sala of items"
                :key="sala.id"
              >
                <td>{{ sala.id }}</td>
                <td>{{ sala.description ? sala.description : "- - -" }}</td>
                <td>{{ sala.status ? sala.status : "- - -" }}</td>
                <td>
                  {{
                    sala.categoria_de_sala.description
                      ? sala.categoria_de_sala.description
                      : "- - -"
                  }}
                </td>
                <td>
                  {{
                    sala.subcategoria_de_sala.description
                      ? sala.subcategoria_de_sala.description
                      : "- - -"
                  }}
                </td>
                <td>
                  <v-tooltip v-if="!sala.estaDeletado" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="blue-grey lighten-3"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => editarSala(sala)"
                      >
                        <v-icon small>fa fa-edit </v-icon>
                      </v-btn>
                    </template>
                    <span>Editar sala</span>
                  </v-tooltip>
                  <v-tooltip v-if="!sala.estaDeletado" color="error" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="error"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => deletarSala(sala)"
                      >
                        <v-icon small>fa fa-trash </v-icon>
                      </v-btn>
                    </template>
                    <span>Apagar sala</span>
                  </v-tooltip>
                  <v-tooltip v-if="sala.estaDeletado" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="info"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => recuperarSala(sala)"
                      >
                        <v-icon small>fa fa-sync </v-icon>
                      </v-btn>
                    </template>
                    <span>Recuperar sala</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </main-template>
</template>

<script>
import { mapGetters } from "vuex";
import { dataTableFilter } from "@/plugins/searchQuery";

export default {
  computed: {
    ...mapGetters("Salas", ["salasList", "carregandoSalas"]),
  },
  data() {
    return {
      dataTableFilter,
      search: "",
      table: {
        headers: [
          { text: "#", value: "id" },
          { text: "Descrição", value: "description" },
          { text: "Status", value: "status" },
          { text: "Categoria de Sala", value: "categoria_de_sala" },
          { text: "Subcategoria de Sala", value: "subcategoria_de_sala" },
          { text: "Ações", value: "actions", sortable: false },
        ],
      },
    };
  },
  mounted() {
    this.$store.dispatch("Salas/loadSalas");
  },
  watch: {
    carregandoSalas(value) {
      if (value && this.salasList.length === 0) {
        this.$loaderService.open("Carregando salas");
      } else {
        this.$loaderService.close();
      }
    },
  },
  methods: {
    criarSala() {
      this.$router.push({ name: "salas.create" });
    },
    editarSala(sala) {
      this.$router.push({ name: "salas.edit", params: { sala_id: sala.id } });
      // return serie;
    },
    async deletarSala(sala) {
      this.$modals.danger({
        title: "Cuidado!",
        message: "Tem certeza que deseja apagar esta sala?",
        confirmationCode: sala.id,
        confirmationMessage: `Por favor, digite <strong>${sala.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Deletando a Sala");
              try {
                await this.$services.salasService.deletarSala(sala);
                this.$toast.success("Sala deletada com sucesso");
                this.$forceUpdate();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              // text: true,
              small: true,
            },
          },
        ],
      });
    },
    recuperarSala(sala) {
      this.$modals.warn({
        title: "Atenção!",
        message: "Tem certeza que deseja recuperar esta sala?",
        confirmationCode: sala.id,
        confirmationMessage: `Por favor, digite <strong>${sala.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Recuperando a Sala");
              try {
                await this.$services.salaService.recuperarSerie(sala);
                this.$toast.success("Sala recuperada com sucesso");
                this.$forceUpdate();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              // text: true,
              small: true,
            },
          },
        ],
      });
    },
  },
};
</script>

<style></style>
