<template>
  <div class="">
    <v-badge bordered :color="color" :content="quant" overlap>
      <v-btn class="white--text" :color="color" depressed x-small>
        {{ descricaoTitle || "- - -" }}
      </v-btn>
    </v-badge>
    <div class="mx-3"></div>
  </div>
</template>
<script>
export default {
  name: "legenda",
  props: {
    status: {
      type: Object,
      required: true,
    },
    i: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      descricaoTitle: this.status.title,
      color: this.status.color,
      quant: this.status.quant,
    };
  },
};
</script>
