<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="
              () =>
                $router.push({
                  name: 'aulas',
                  params: { gestaoDeAulaId: $route.params.gestaoDeAulaId },
                })
            "
            :disabled="submittingForm"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          {{ editing ? "Editando" : "Criando" }} Aula
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" v-if="gestaoDeAula">
        <v-card color="green" class="mb-5" outlined>
          <v-card-text>
            <h4>Turma: {{ gestaoDeAula.turma.descricao }}</h4>
            <h4>Disciplina: {{ gestaoDeAula.disciplina.descricao }}</h4>
            <h4>
              Professor:
              {{ gestaoDeAula.professor ? gestaoDeAula.professor.nome : "[NÃO INFORMADO]" }}
            </h4>
            <h4>
              Turno:
              {{
                gestaoDeAula.turma.turno ? gestaoDeAula.turma.turno.descricao : "[NÃO INFORMADO]"
              }}
            </h4>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submitForm)" :disabled="submittingForm">
        <v-row>
          <v-col class="pt-0 pb-0" cols="12">
            <ValidationProvider name="Tipo de Aula" rules="required" v-slot="{ errors }">
              <e-label>Tipo de aula</e-label>
              <e-autocomplete
                :items="$constants.tiposDeAulas"
                :return-object="false"
                :error-messages="errors"
                v-model="aulaSelecionada.tipo_de_aula"
                label="Selecione um tipo de aula"
                dense
                solo
              />
            </ValidationProvider>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="pt-0 pb-0" cols="4">
            <v-dialog ref="dialog" v-model="modal" persistent width="290px">
              <template v-slot:activator="{ on, attrs }">
                <e-label>Selecione uma data</e-label>
                <v-text-field
                  v-model="aulaSelecionada.data"
                  persistent-hint
                  label="Escolha uma data"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  dense
                  solo
                ></v-text-field>
              </template>
              <v-date-picker v-model="data" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modal = false"> Cancel </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="
                    $refs.dialog.save(
                      (data = data
                        ? data
                        : new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                            .toISOString()
                            .substr(0, 10))
                    )
                  "
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>

          <v-col class="pt-0 pb-0" v-if="aulaSelecionada.tipo_de_aula !== 'Aula Remota'" cols="4">
            <ValidationProvider name="Horário da aula" rules="required" v-slot="{ errors }">
              <e-label>Selecione um horário</e-label>
              <e-autocomplete
                :items="horarios"
                :error-messages="errors"
                label="Selecione uma opção"
                :item-text="(item) => item.descricao"
                v-model="horarioSelecionado"
                return-object
                dense
              />
            </ValidationProvider>
          </v-col>

          <v-col class="pt-0 pb-0" cols="4">
            <e-label>Dia da semana</e-label>
            <v-text-field solo dense v-model="aulaSelecionada.dia_da_semana" :disabled="true">
            </v-text-field>
          </v-col>

          <v-col cols="12"> </v-col>
          <v-col class="pt-0 pb-0" cols="12">
            <h4>Campos de experiencias</h4>
            <v-row class="">
              <v-col class="pt-0 pb-0" cols="4" v-for="(el, index) of opcoes" :key="index">
                <v-checkbox
                  v-model="campos_de_experiencias[index]"
                  :label="el"
                  :value="el"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-col>

          <v-col
            cols="12"
            v-if="horarioSelecionado || aulaSelecionada.tipo_de_aula === 'Aula Remota'"
          >
            <ValidationProvider name="conteudo" rules="required" v-slot="{ errors }">
              <e-label>Eixos temáticos</e-label>
              <v-textarea solo dense v-model="aulaSelecionada.eixos" :error-messages="errors" />
            </ValidationProvider>

            <ValidationProvider name="estrategias" rules="required" v-slot="{ errors }">
              <e-label>Estratégias de ensino </e-label>
              <v-textarea
                solo
                dense
                v-model="aulaSelecionada.estrategias"
                :error-messages="errors"
              />
            </ValidationProvider>

            <ValidationProvider name="recursos" rules="required" v-slot="{ errors }">
              <e-label>Recursos</e-label>
              <v-textarea solo dense v-model="aulaSelecionada.recursos" :error-messages="errors" />
            </ValidationProvider>

            <ValidationProvider name="atividade_classe" rules="required" v-slot="{ errors }">
              <e-label>Atividade de classe</e-label>
              <v-textarea
                solo
                dense
                v-model="aulaSelecionada.atividade_classe"
                :error-messages="errors"
              />
            </ValidationProvider>

            <ValidationProvider name="atividade_casa" rules="required" v-slot="{ errors }">
              <e-label>Atividade de casa</e-label>
              <v-textarea
                solo
                dense
                v-model="aulaSelecionada.atividade_casa"
                :error-messages="errors"
              />
            </ValidationProvider>

            <e-label>Observações</e-label>
            <v-textarea solo dense v-model="aulaSelecionada.observacoes" />

            <v-btn
              color="primary"
              :disabled="submittingForm"
              :loading="submittingForm"
              type="submit"
              >Enviar Dados
            </v-btn>
            <v-btn v-if="editing" class="ml-3" color="success" @click="dialog.duplicar = true"
              >Duplicar Aula</v-btn
            >
            <v-btn v-if="editing" class="ml-3" color="success" @click="dialog.exportar = true"
              >Exportar Aula</v-btn
            >
          </v-col>
        </v-row>
      </form>
    </ValidationObserver>
    <e-modal-duplicar-aula
      :submittingForm="submittingForm"
      :dialog="dialog.duplicar"
      :gestaoDeAulas="aulaSelecionada"
      :form="{ ...aulaSelecionada }"
      :horarios="horarios"
      @dialogChange="dialog.duplicar = $event"
    >
    </e-modal-duplicar-aula>
    <e-modal-exportar-aula
      :submittingForm="submittingForm"
      :dialog="dialog.exportar"
      :form="{ ...aulaSelecionada }"
      @dialogChange="dialog.exportar = $event"
      :horarios="horarios"
    >
    </e-modal-exportar-aula>
  </main-template>
</template>

<script>
import ELabel from "../../components/ELabel.vue";

export default {
  components: { ELabel },
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
    aula_id: {
      type: Number,
      require: true,
    },
  },
  computed: {
    tipo_de_aula() {
      return this.aulaSelecionada.tipo_de_aula;
    },
  },
  mounted() {
    this.loadData();
  },
  data() {
    return {
      opcoes: [
        "O eu, o outro e o nós",
        "Corpo, gestos e movimentos",
        "Escuta, fala, pensamento e imaginação",
        "Traços, sons, cores e formas",
        "Espaço, tempo, quantidades, relações e transformações",
      ],
      data: null,
      modal: false,
      menu: false,
      aulasEmConflitos: false,
      aulasConflitadas: [],
      diasSemana: this.$constants.diasDaSemana,
      mostrarCampos: true,
      outrosCampos: false,
      submittingForm: false,
      form: {},
      campos_de_experiencias: [],
      aulaSelecionada: {
        data: null,
        dia_da_semana: "",
      },
      gestaoDeAula: {
        turma: {
          descricao: "",
        },
        disciplina: {
          descricao: "",
        },
      },
      aulaDuplicada: {},
      gestaoDeAulas: null,
      dialog: {
        duplicar: false,
        exportar: false,
      },
      horarios: [],
      horarioSelecionado: null,
    };
  },
  watch: {
    data(value) {
      const date = new Date(value);
      const data_old = new Intl.DateTimeFormat("pt-BR", { timeZone: "UTC" }).format(date);
      this.aulaSelecionada.data = data_old.replaceAll("/", "/");
      this.diaDaSemana();
    },
  },
  methods: {
    async submitForm() {
      this.aulaSelecionada.e_aula_infantil = 1;
      this.aulaSelecionada.campos_de_experiencias = this.campos_de_experiencias;
      this.aulaSelecionada.horario_inicial = this.horarioSelecionado
        ? this.horarioSelecionado.inicio
        : null;
      this.aulaSelecionada.horario_final = this.horarioSelecionado
        ? this.horarioSelecionado.final
        : null;
      this.aulaSelecionada.horario_id = this.horarioSelecionado ? this.horarioSelecionado.id : null;
      this.submittingForm = true;
      try {
        this.aulaSelecionada.gestao_de_aula = parseInt(this.$route.params.gestaoDeAulaId, 10);
        this.aulaSelecionada.data = this.aulaSelecionada.data.split("/").reverse().join("-");
        await this.$services.aulasService.atualizar(this.aulaSelecionada);
        this.$toast.success("Aula atualizada com sucesso!");
      } catch (error) {
        this.$handleError(error);
      }
      this.submittingForm = false;
      this.$router.push({
        name: "aulas",
      });
    },
    async loadData() {
      this.$loaderService.open("Carregando dados...");

      const { aula_id, gestaoDeAulaId } = this.$route.params;
      const response = await this.$services.gestoesDeAulasService.listarAulasGestao(gestaoDeAulaId);

      this.gestaoDeAula = response.gestaoDeAula;
      this.horarios = response.horarios;

      const aulaSelecionada = await this.$services.aulasService.getAula(parseInt(aula_id, 10));

      this.aulaSelecionada = aulaSelecionada;
      if (this.aulaSelecionada.data) {
        this.aulaSelecionada.data = this.aulaSelecionada.data.split("-").reverse().join("/");
      }

      this.horarioSelecionado = this.aulaSelecionada.horario;
      this.campos_de_experiencias = this.aulaSelecionada.campos_de_experiencias;

      this.$loaderService.close();
    },

    diaDaSemana() {
      const data_do_usuario = this.aulaSelecionada.data;
      const data_do_usuario_formatada = data_do_usuario.replace(/\//g, "-");
      const array1 = data_do_usuario_formatada.split("-");
      const array2 = array1.reverse();
      const data_final = new Date(array2[0], parseInt(array2[1], 10) - 1, array2[2]);
      const dia = this.diasSemana[data_final.getDay()];
      this.aulaSelecionada.dia_da_semana = dia;
    },
  },
};
</script>
<style scoped>
.dataInput {
  background-color: white;
  width: 100%;
  height: 35px;
  box-shadow: 2px 2px rgb(0, 0, 0, 0.1);
}
</style>
