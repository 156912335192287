<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="() => $router.push({ name: 'turmas' })"
            :disabled="false"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          Turma
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" v-if="turma">
        <v-card color="secondary" class="mb-5" outlined>
          <v-card-title> Turma: {{ turma.descricao }}</v-card-title>
          <v-card-subtitle class="font-weight-medium">
            <div>Diários relacionados: {{ turma.gestao_de_aulas.length || "- - -" }} unidades</div>
            <div>Total de alunos: {{ matriculas.length || "- - -" }}</div></v-card-subtitle
          >
        </v-card>
      </v-col>
    </v-row>
    <v-card class="mt-3">
      <template>
        <v-tabs v-model="tab_atual" background-color="primary" dark>
          <v-tab v-for="tab of tabs" :key="tabs.indexOf(tab)">{{ tab.descricao }}</v-tab>
        </v-tabs>
        <div class="d-flex text-right">
          <v-btn color="primary" class="ml-4 mt-4 mr-4 white--text" @click="gerarRelatorio">
            Lista da turma
            <v-icon right dark> mdi-file </v-icon>
          </v-btn>
          <v-btn
            v-if="!this.isHidden"
            color="primary"
            class="ml-4 mt-4 mr-4 white--text"
            @click="gerarBoletimGeral"
          >
            Boletim da turma
            <v-icon right dark> class </v-icon>
          </v-btn>
          <v-btn
            v-if="!this.isHidden"
            color="primary"
            class="ml-4 mt-4 mr-4 white--text"
            @click="resultadoPorDisciplina"
          >
            Resultado por disciplina
            <v-icon right dark> class </v-icon>
          </v-btn>
        </div>
        <v-card-text>
          <v-simple-table v-if="matriculas" class="elevation-1" v-show="tab_atual == 0">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Nº</th>
                  <th class="text-left">Aluno</th>
                  <th class="text-center">Ações</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="matricula of matriculas"
                  :key="matricula.id"
                  :class="
                    $constants.situacoesMatriculaCondition[
                      removerEspaco(matricula.situacao ? matricula.situacao : '')
                    ]
                      ? 'red lighten-4'
                      : null
                  "
                >
                  <td width="20">{{ matricula.id }}</td>
                  <td class="font-weight-medium text-left">
                    <div>{{ matricula.aluno.nomecompleto }} <br /></div>
                    <v-chip
                      label
                      color="error"
                      small
                      v-if="
                        $constants.situacoesMatriculaCondition[
                          removerEspaco(matricula.situacao ? matricula.situacao : '')
                        ]
                      "
                      >{{ matricula.situacao }}</v-chip
                    >
                    <div>
                      <small
                        >{{ matricula.aluno.codigo_inep || "- - -" }} /
                        {{ matricula.aluno.codigo || "- - -" }}</small
                      >
                    </div>
                  </td>
                  <td class="text-center">
                    <v-tooltip bottom v-if="matricula.aluno && !isHidden">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          x-small
                          v-if="
                            !$constants.situacoesMatriculaCondition[
                              removerEspaco(matricula.situacao ? matricula.situacao : '')
                            ]
                          "
                          class="ml-2"
                          color="red lighten-5"
                          v-bind="attrs"
                          v-on="on"
                          @click="() => boletimIndividual(matricula.id)"
                        >
                          <v-icon color="red lighten-1" small> fa fa-file-pdf</v-icon>
                        </v-btn>
                      </template>
                      <span>Boletim individual</span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="matricula.aluno && isHidden">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          x-small
                          class="ml-2"
                          color="red lighten-5"
                          v-bind="attrs"
                          v-on="on"
                          @click="() => RelatorioBNCC(matricula.id)"
                        >
                          <v-icon color="red lighten-1" small> fa fa-file-pdf</v-icon>
                        </v-btn>
                      </template>
                      <span>Gerar relatório infantil</span>
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <v-simple-table v-if="gestoes_de_aulas" class="elevation-1" v-show="tab_atual == 1">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">N º</th>
                  <th class="text-left" width="300px">Disciplina</th>
                  <th class="text-left" width="300px">Professor</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="diario of gestoes_de_aulas" :key="gestoes_de_aulas.indexOf(diario)">
                  <td width="20">{{ gestoes_de_aulas.indexOf(diario) + 1 }}</td>
                  <td>{{ diario.disciplina.descricao }}</td>
                  <td>{{ diario.professor.nome }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </template>
    </v-card>
  </main-template>
</template>

<script>
export default {
  data() {
    return {
      turma: null,
      matriculas: null,
      gestoes_de_aulas: null,
      tab_atual: 0,
      tabs: [
        {
          id: 0,
          descricao: "Lista da Turma",
        },
        {
          id: 1,
          descricao: "Diários Relacionados",
        },
      ],
      isHidden: false,
    };
  },
  mounted() {
    this.loadBasicData();
  },
  methods: {
    async gerarRelatorio() {
      this.$loaderService.open("Carregando dados...");
      await this.$services.relatoriosService.listarTurma(parseInt(this.$route.params.turma_id, 10));
      this.$loaderService.close();
    },

    async resultadoPorDisciplina() {
      this.$loaderService.open("Carregando dados...");
      try {
        await this.$services.relatoriosService.resultadoPorDisciplina(
          parseInt(this.$route.params.turma_id, 10)
        );
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },

    async gerarBoletimGeral() {
      this.$loaderService.open("Carregando dados...");
      await this.$services.relatoriosService.boletimGeral(
        parseInt(this.$route.params.turma_id, 10)
      );
      this.$loaderService.close();
    },

    async RelatorioBNCC(matricula_id) {
      this.$loaderService.open("Carregando dados...");
      await this.$services.relatoriosService.relatorioBNCC(parseInt(matricula_id, 10));
      this.$loaderService.close();
    },

    async boletimIndividual(id) {
      this.$loaderService.open("Carregando dados...");
      await this.$services.relatoriosService.boletimIndividual(parseInt(id, 10));
      this.$loaderService.close();
    },

    async loadBasicData() {
      this.$loaderService.open("Carregando dados...");
      const payload = await this.$services.turmasService.visualizarTurma(
        parseInt(this.$route.params.turma_id, 10)
      );
      this.turma = payload.turma;
      this.matriculas = payload.matriculas;
      this.isHidden = payload.turma.sistema_bncc_id === 1;
      this.gestoes_de_aulas = payload.gestoes_de_aulas;
      this.$loaderService.close();
    },
    removerEspaco(string) {
      return string.replace(" ", "_").replace(" ", "_").replace(" ", "_");
    },
  },
};
</script>

<style></style>
