var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main-template',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mt-3"},[_c('v-tabs',{attrs:{"background-color":"primary","dark":""},model:{value:(_vm.tab_atual),callback:function ($$v) {_vm.tab_atual=$$v},expression:"tab_atual"}},_vm._l((_vm.etapas),function(tab){return _c('v-tab',{key:tab.id,on:{"click":function($event){_vm.loadSubetapas(tab.id, _vm.etapas.indexOf(tab))}}},[_vm._v(" "+_vm._s(tab.descricao)+" ")])}),1),_c('v-card-text',[(_vm.matriculas)?_c('v-simple-table',{staticClass:"elevation-1",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("N º")]),(_vm.user.roleName == 'super-admin' || _vm.user.roleName == 'admin')?_c('th',{staticClass:"text-left"},[_vm._v(" Identificador ")]):_vm._e(),_c('th',{staticClass:"text-left",attrs:{"width":"300px"}},[_vm._v("Aluno")]),_vm._l((_vm.subetapas),function(sub){return _c('th',{directives:[{name:"show",rawName:"v-show",value:(_vm.tab_atual === _vm.etapaAtual),expression:"tab_atual === etapaAtual"}],key:_vm.subetapas.indexOf(sub),staticClass:"text-left"},[_vm._v(" "+_vm._s(sub.descricao)+" "+_vm._s(sub.valor_maximo != 10 ? `(Nota Máxima: ${sub.valor_maximo})` : "")+" ")])})],2)]),_c('tbody',_vm._l((_vm.matriculas),function(matricula){return _c('tr',{key:matricula.id,class:_vm.$constants.situacoesMatriculaCondition[
                      _vm.removerEspaco(matricula.situacao ? matricula.situacao : '')
                    ]
                      ? 'red lighten-4'
                      : null},[_c('td',[_vm._v(_vm._s(_vm.matriculas.indexOf(matricula) + 1))]),(_vm.user.roleName == 'super-admin' || _vm.user.roleName == 'admin')?_c('td',[_vm._v(" "+_vm._s(matricula.id)+" ")]):_vm._e(),_c('td',{attrs:{"width":"300px"}},[_vm._v(" "+_vm._s(matricula.aluno.nomecompleto)+" "),(
                        _vm.$constants.situacoesMatriculaCondition[
                          _vm.removerEspaco(matricula.situacao ? matricula.situacao : '')
                        ]
                      )?_c('v-chip',{attrs:{"label":"","color":"error","small":""}},[_vm._v(_vm._s(matricula.situacao))]):_vm._e()],1),_vm._l((_vm.subetapas),function(sub){return _c('td',{directives:[{name:"show",rawName:"v-show",value:(_vm.tab_atual == _vm.etapaAtual),expression:"tab_atual == etapaAtual"}],key:_vm.subetapas.indexOf(sub)},[(_vm.notas[matricula.id][sub.id] && sub.tipo_campo !== 'resultado_em_lote')?_c('e-nota-input',{attrs:{"max":sub.valor_maximo,"matricula":matricula},on:{"change":function($event){return _vm.verificarRegra(sub.etapa_id, matricula.id, 'jaSalva')}},model:{value:(_vm.notas[matricula.id][sub.id].valor),callback:function ($$v) {_vm.$set(_vm.notas[matricula.id][sub.id], "valor", $$v)},expression:"notas[matricula.id][sub.id].valor"}}):(
                        _vm.notas[matricula.id][sub.id] && sub.tipo_campo === 'resultado_em_lote'
                      )?_c('e-nota-input',{attrs:{"matricula":matricula},model:{value:(_vm.notas[matricula.id][sub.id].valor),callback:function ($$v) {_vm.$set(_vm.notas[matricula.id][sub.id], "valor", $$v)},expression:"notas[matricula.id][sub.id].valor"}}):(
                        _vm.notas[matricula.id].valores[sub.id] &&
                        sub.tipo_campo === 'resultado_em_lote'
                      )?_c('e-nota-input',{attrs:{"matricula":matricula},model:{value:(_vm.notas[matricula.id].valores[sub.id]),callback:function ($$v) {_vm.$set(_vm.notas[matricula.id].valores, sub.id, $$v)},expression:"notas[matricula.id].valores[sub.id]"}}):_c('e-nota-input',{attrs:{"max":sub.valor_maximo,"matricula":matricula},on:{"change":function($event){return _vm.verificarRegra(sub.etapa_id, matricula.id, 'naoSalva')}},model:{value:(_vm.notas[matricula.id].valores[sub.id]),callback:function ($$v) {_vm.$set(_vm.notas[matricula.id].valores, sub.id, $$v)},expression:"notas[matricula.id].valores[sub.id]"}})],1)})],2)}),0)]},proxy:true}],null,false,3499618879)}):_vm._e(),_c('v-expand-transition',[(_vm.temAlteracoesNaoSalvas)?_c('div',{staticClass:"d-flex justify-end mt-2"},[_c('span',{staticClass:"font-weight-bold warning--text"},[_vm._v("Você tem algumas notas não salvas...")])]):_vm._e()])],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"success","loading":_vm.promiseCount > 0,"block":""},on:{"click":_vm.salvarNotas}},[_vm._v("Enviar Notas")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }