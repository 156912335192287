import Model from "./Model";

export default class GestaoDeAula extends Model {
  constructor(realacao) {
    super();
    Object.assign(this, realacao);
    this.nomeDisciplina = this.disciplina ? this.disciplina.descricao : "- - -";
    this.nomeTurma = this.turma ? this.turma.descricao : "- - -";
    this.nomeProfessor = this.professor ? this.professor.nome : "- - -";
    this.nomeFranquia = this.turma && this.turma.franquia ? this.turma.franquia.descricao : "- - -";
  }
}
