<template>
  <main-template>
    <v-row>
      <v-col cols="12">
        <v-card class="mt-3">
          <v-tabs background-color="primary" dark v-model="tab_atual">
            <v-tab
              @click="loadSubetapas(tab.id, etapas.indexOf(tab))"
              v-for="tab of etapas"
              :key="tab.id"
            >
              {{ tab.descricao }}
            </v-tab>
          </v-tabs>
          <v-card-text>
            <v-simple-table v-if="matriculas" class="elevation-1">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">N º</th>
                    <th
                      v-if="user.roleName == 'super-admin' || user.roleName == 'admin'"
                      class="text-left"
                    >
                      Identificador
                    </th>
                    <th class="text-left" width="300px">Aluno</th>
                    <th
                      v-for="sub of subetapas"
                      :key="subetapas.indexOf(sub)"
                      class="text-left"
                      v-show="tab_atual === etapaAtual"
                    >
                      {{ sub.descricao }}
                      {{ sub.valor_maximo != 10 ? `(Nota Máxima: ${sub.valor_maximo})` : "" }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="matricula of matriculas"
                    :key="matricula.id"
                    :class="
                      $constants.situacoesMatriculaCondition[
                        removerEspaco(matricula.situacao ? matricula.situacao : '')
                      ]
                        ? 'red lighten-4'
                        : null
                    "
                  >
                    <td>{{ matriculas.indexOf(matricula) + 1 }}</td>
                    <td v-if="user.roleName == 'super-admin' || user.roleName == 'admin'">
                      {{ matricula.id }}
                    </td>
                    <td width="300px">
                      {{ matricula.aluno.nomecompleto }}

                      <v-chip
                        label
                        color="error"
                        small
                        v-if="
                          $constants.situacoesMatriculaCondition[
                            removerEspaco(matricula.situacao ? matricula.situacao : '')
                          ]
                        "
                        >{{ matricula.situacao }}</v-chip
                      >
                    </td>
                    <td
                      v-show="tab_atual == etapaAtual"
                      v-for="sub of subetapas"
                      :key="subetapas.indexOf(sub)"
                    >
                      <e-nota-input
                        :max="sub.valor_maximo"
                        :matricula="matricula"
                        v-if="notas[matricula.id][sub.id] && sub.tipo_campo !== 'resultado_em_lote'"
                        v-model="notas[matricula.id][sub.id].valor"
                        @change="verificarRegra(sub.etapa_id, matricula.id, 'jaSalva')"
                      />
                      <e-nota-input
                        :matricula="matricula"
                        v-else-if="
                          notas[matricula.id][sub.id] && sub.tipo_campo === 'resultado_em_lote'
                        "
                        v-model="notas[matricula.id][sub.id].valor"
                      />
                      <e-nota-input
                        :matricula="matricula"
                        v-else-if="
                          notas[matricula.id].valores[sub.id] &&
                          sub.tipo_campo === 'resultado_em_lote'
                        "
                        v-model="notas[matricula.id].valores[sub.id]"
                      />

                      <e-nota-input
                        :max="sub.valor_maximo"
                        :matricula="matricula"
                        v-else
                        v-model="notas[matricula.id].valores[sub.id]"
                        @change="verificarRegra(sub.etapa_id, matricula.id, 'naoSalva')"
                      />
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-expand-transition>
              <div class="d-flex justify-end mt-2" v-if="temAlteracoesNaoSalvas">
                <span class="font-weight-bold warning--text"
                  >Você tem algumas notas não salvas...</span
                >
              </div>
            </v-expand-transition>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-btn color="success" :loading="promiseCount > 0" block @click="salvarNotas"
          >Enviar Notas</v-btn
        >
      </v-col>
    </v-row>
  </main-template>
</template>

<script>
import { mapGetters } from "vuex";

const TIMEOUT = 30000;
const NOTA_MINIMA = 6;

export default {
  mounted() {
    this.loadData();
    setTimeout(() => this.handleChanges(), TIMEOUT);
    window.addEventListener("beforeunload", (event) => this.onCloseWindow(event));
  },
  computed: {
    ...mapGetters("Auth", ["user"]),
  },
  data() {
    return {
      etapaAtual: 0,
      circuitoName: null,
      etapas: null,
      regras: null,
      NOTA_MINIMA,
      notas: null,
      disciplina: null,
      turma: null,
      professor: null,
      matriculas: null,
      tab_atual: 0,
      teste: null,
      tabs: [],
      promiseCount: 0,
      temAlteracoesNaoSalvas: true,
      subetapas: null,
      idEtapaAtual: null,
    };
  },
  methods: {
    loadSubetapas(etapa_id, indexEtapa) {
      this.etapaAtual = indexEtapa;
      this.idEtapaAtual = etapa_id;

      const etapas = Object.values(this.etapas);

      let etapa = etapas.filter((d) => d.id === etapa_id);
      etapa = etapa.shift();

      this.subetapas = etapa.subetapas;
      const firstItemNota = this.notas[Object.keys(this.notas).shift()];
      if (firstItemNota.valores) {
        this.verificarRegraEmLote(etapa_id, "naoSalva");
      } else {
        this.verificarRegraEmLote(etapa_id, "jaSalva");

        // limpar inputs das outras etapas
        etapa.subetapas.forEach((subetapa) => {
          this.matriculas.forEach((matricula) => {
            if (this.notas[matricula.id][subetapa.id].valor === null) {
              this.notas[matricula.id][subetapa.id].valor = ".";
            }
            if (this.notas[matricula.id][subetapa.id].valor === "0.00") {
              this.notas[matricula.id][subetapa.id].valor = ".";
            }
          });
        });
      }
    },

    async loadData() {
      try {
        // dados circuito e etapas
        this.$loaderService.open("Carregando dados");
        const data = await this.$services.notasService.getCircuitoEtapas(
          parseInt(this.$route.params.gestaoDeAulasId, 10)
        );
        this.etapas = data.etapas;
        this.regras = data.regras;
        this.circuitoName = data.descricao;
        const firstEtapa = Object.values(this.etapas)[0];
        this.subetapas = firstEtapa.subetapas;
        // dados notas
        const payload = await this.$services.notasService.getNotasCircuito(
          parseInt(this.$route.params.gestaoDeAulasId, 10)
        );
        this.notas = payload.notasCircuito;
        this.disciplina = payload.disciplina;
        this.turma = payload.turma;
        this.professor = payload.professor;
        this.matriculas = payload.matriculas;

        const firstItemNota = this.notas[Object.keys(this.notas).shift()];

        if (!firstItemNota.valores) {
          this.verificarRegraEmLote(null, "jaSalva");

          // limpar inputs das outras etapas
          firstEtapa.subetapas.forEach((subetapa) => {
            this.matriculas.forEach((matricula) => {
              if (this.notas[matricula.id][subetapa.id].valor === null) {
                this.notas[matricula.id][subetapa.id].valor = "";
              }
              if (this.notas[matricula.id][subetapa.id].valor === "0.00") {
                this.notas[matricula.id][subetapa.id].valor = "";
              }
            });
          });
        } else {
          this.verificarRegraEmLote(null, "naoSalva");
        }

        this.$loaderService.close();
        this.$forceUpdate();
      } catch (error) {
        this.$handleError(error);
      }
    },

    removerEspaco(string) {
      return string.replace(" ", "_").replace(" ", "_").replace(" ", "_");
    },
    verificarSituacao() {
      return true;
    },
    // calcularMedia(nota1, nota2) {
    //   const media =
    //     ((nota1 ? parseFloat(nota1, 10) : 0) + (nota2 ? parseFloat(nota2, 10) : 0)) / 2.0;
    //   return Math.trunc(media * 10) / 10.0;
    // },

    async salvarNotas() {
      if (!this.temAlteracoesNaoSalvas) {
        this.$toast.info("Todas as notas estão salvas no sistema 😁");
        return null;
      }
      this.$toast.info("Salvando notas...");
      this.promiseCount += 1;
      let response;
      try {
        response = await this.$services.notasService.salvarNotasCircuito(
          parseInt(this.$route.params.gestaoDeAulasId, 10),
          this.notas
        );
      } catch (error) {
        this.$handleError(error);
      }
      this.promiseCount -= 1;
      if (this.promiseCount === 0) {
        this.temAlteracoesNaoSalvas = true;
      }
      return response;
    },
    async handleChanges() {
      if (this.$route.name !== "notas.lancarNotas") return;
      setTimeout(() => this.handleChanges(), TIMEOUT);
      try {
        await this.salvarNotas();
      } catch (error) {
        this.$handleError(error);
      }
    },

    recuperacao(nota1, nota2, rec) {
      let result = 0;
      const Fnota1 = parseFloat(nota1, 10) || 0;
      const Fnota2 = parseFloat(nota2, 10) || 0;
      const Frec = parseFloat(rec, 10) || 0;
      result = (Fnota1 + Fnota2) / 2;
      const mediaSemestral = Math.trunc(result * 10) / 10.0;

      if (!rec && mediaSemestral < NOTA_MINIMA) {
        return mediaSemestral;
      }

      if (mediaSemestral < NOTA_MINIMA) {
        const maiorNota = Math.max(Fnota1, Fnota2);
        result = (maiorNota + Frec) / 2;
        const nota = Math.trunc(result * 10) / 10.0;
        return nota >= mediaSemestral ? nota : mediaSemestral;
      }
      return mediaSemestral;
    },

    onCloseWindow(event) {
      if (this.temAlteracoesNaoSalvas || this.promiseCount > 0) {
        event.preventDefault();
        return "Tem certeza que deseja fechar esta página? existem notas não salvas";
      }
      return undefined;
    },

    verificarRegraEmLote(etapaId, situacao) {
      if (etapaId) {
        let etapa = this.etapas.filter((d) => d.id === etapaId);
        etapa = etapa.shift();
        etapa.subetapas.forEach((subetapa) => {
          if (subetapa.tipo_campo === "resultado_em_lote") {
            this.matriculas.forEach((matricula) => {
              this.verificarRegra(etapa.id, matricula.id, situacao);
            });
          }
        });
      } else {
        const etapa = this.etapas[0];

        etapa.subetapas.forEach((subetapa) => {
          if (subetapa.tipo_campo === "resultado_em_lote") {
            this.matriculas.forEach((matricula) => {
              this.verificarRegra(etapa.id, matricula.id, situacao);
            });
          }
        });
      }
    },
    verificarRegra(etapa_id, matricula_id, type) {
      let etapaCurrent = this.etapas.filter((d) => d.id === etapa_id);
      etapaCurrent = etapaCurrent.shift();

      const regra = Object.values(etapaCurrent.regras)[0];
      if (regra) {
        let expressaoCod = regra.expressao_codigo;
        const variaveis = regra.subetapas.split(",");
        const campo_resultado = regra.id_subetapa_resultado;

        if (type !== "naoSalva") {
          const promisse = new Promise(() => {
            const av = [];
            variaveis.forEach((element) => {
              av[element] = parseFloat(this.notas[matricula_id][element].valor);
            });
            return (expressaoCod = eval(expressaoCod));
          });

          this.teste = promisse;
          this.notas[matricula_id][campo_resultado].valor = expressaoCod;
        } else {
          const promisse = new Promise(() => {
            const av = [];
            variaveis.forEach((element) => {
              av[element] = parseFloat(this.notas[matricula_id].valores[element]);
            });

            return (expressaoCod = eval(expressaoCod));
          });
          this.teste = promisse;
          this.notas[matricula_id].valores[campo_resultado] = expressaoCod;
        }
      }
    },
  },
};
</script>
