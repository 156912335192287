<template>
  <main-template hide-franquia>
    <v-row>
      <v-col cols="12" class="mx-4 pt-10">
        <h1>Escolas:</h1>
      </v-col>
      <v-col cols="12" v-if="franquiasList.length === 0">
        <v-alert color="warning" icon="fa fa-frown-o"
          >Infelizmente ainda não lhe adicionaram em nenhuma Escola, entre em contato com o setor de
          secretaria/coordenação da sua escola para que criem seus vínculos no diário e suas escolas
          serão listadas aqui.</v-alert
        >
      </v-col>
      <v-col
        v-else
        cols="12"
        md="4"
        class="mx-4"
        v-for="franquia of franquiasList"
        :key="franquia.id"
        align-self="stretch"
      >
        <v-card
          color="indigo darken-4 white--text"
          @click="selecionarFranquiaERedirecionar(franquia)"
          style="height: 100%"
          class="d-flex flex-column justify-space-between"
        >
          <v-card-title>
            <v-icon large left color="white"> mdi-account-multiple </v-icon>
            <span class="title font-weight-light">{{ franquia.descricao }}</span>
          </v-card-title>
          <v-card-text class="subtitle font-weight-bold white--text">
            Diários: {{ franquia.qtdGestoesDeAulas }}
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </main-template>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("Franquias", ["franquiasList", "carregandoFranquias"]),
  },
  methods: {
    async selecionarFranquiaERedirecionar(franquia) {
      try {
        this.$loaderService.open("Acessando diários");
        await this.$services.authService.mudarDeFranquia(franquia);
        this.$router.push({ name: "gestoesDeAulas" });
        this.$loaderService.close();
      } catch (error) {
        this.$handleError(error);
      }
    },
  },
};
</script>
