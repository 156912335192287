import Model from "./Model";

export const SituacaoSalaType = {
  ATIVA: 1,
  DESATIVADA: 0,
};

export default class Sala extends Model {
  constructor(franquia) {
    super();
    Object.assign(this, franquia);
  }

  get ativa() {
    return this.situacao === SituacaoSalaType.ATIVA;
  }

  get desativada() {
    return this.situacao === SituacaoSalaType.DESATIVADA;
  }
}
