<template>
  <v-row class="mt-0 mb-3 ml-0 mr-0 mt-lg-0 mb-lg-0 ml-lg-10 mr-lg-10">
    <strong>{{ dadosPresentes || "0" }} Presentes</strong>
    <div class="mx-1"></div>
    <span> | </span>
    <div class="mx-1"></div>
    <strong>{{ dadosAusentes || "0" }} Ausentes</strong>
    <div class="mx-1"></div>
  </v-row>
</template>
<script>
export default {
  name: "quantidade",
  props: {
    presentes: {
      type: Number,
      required: true,
    },
    ausentes: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      dadosPresentes: this.presentes,
      dadosAusentes: this.ausentes,
    };
  },
};
</script>
