import Auth from "./Auth";
import Mensalidades from "./Mensalidades";
import Caixas from "./Caixas";
import Franquias from "./Franquias";
import Matriculas from "./Matriculas";
import FormasDePagamento from "./FormasDePagamento";
import MainTemplate from "./MainTemplate";
import Turmas from "./Turmas";
import Turnos from "./Turnos";
import Series from "./Series";
import Cursos from "./Cursos";
import Disciplinas from "./Disciplinas";
import Alunos from "./Alunos";
import Professores from "./Professores";
import Usuarios from "./Usuarios";
import Roles from "./Roles";
import GestoesDeAulas from "./GestoesDeAulas";
import Aulas from "./Aulas";
import TiposDeEnsinos from "./TiposDeEnsinos";
import SistemaBNCC from "./SistemaBNCC";
import Salas from "./Salas";
import CategoriaSalas from "./CategoriaSalas";
import SubcategoriaSalas from "./SubcategoriaSalas";
import Horarios from "./Horarios";
import CircuitoNotas from "./CircuitoNotas";
import Etapas from "./Etapas";
import SubEtapas from "./SubEtapas";

export default {
  Auth,
  Mensalidades,
  Caixas,
  Franquias,
  Matriculas,
  FormasDePagamento,
  MainTemplate,
  Turmas,
  Turnos,
  Series,
  Cursos,
  Disciplinas,
  Alunos,
  Professores,
  Usuarios,
  Roles,
  GestoesDeAulas,
  Aulas,
  TiposDeEnsinos,
  SistemaBNCC,
  Salas,
  CategoriaSalas,
  SubcategoriaSalas,
  Horarios,
  CircuitoNotas,
  Etapas,
  SubEtapas,
};
