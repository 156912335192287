import HorariosIndexPage from "@/Pages/Horarios/index.vue";
import NewEditHorarioPage from "@/Pages/Horarios/NewEditHorarioPage.vue";

export default [
  {
    path: "/horarios/",
    name: "horarios",
    component: HorariosIndexPage,
  },
  {
    path: "/horarios/create",
    name: "horarios.create",
    component: NewEditHorarioPage,
    props: { editing: false },
  },
  {
    path: "/horarios/edit/:horario_id",
    name: "horarios.edit",
    component: NewEditHorarioPage,
    props: { editing: true },
  },
];
