<template>
  <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" dark v-bind="attrs" v-on="on">
        <v-icon dark> mdi-plus </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ type }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-list three-line subheader>
        <div class="d-flex flex-column ma-3 mt-0">
          <h3 class="ml-4">{{ status }} {{ type }}</h3>
          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(submitFormDialog)" :disabled="submittingFormDialog">
              <div class="ma-4">
                <ValidationProvider name="Descrição" rules="required" v-slot="{ errors }">
                  <e-label>Descrição</e-label>
                  <v-text-field
                    v-on:change="(value) => deixarLetraMinuscula(value, 'description')"
                    :error-messages="errors"
                    v-model="description"
                    dense
                    solo
                  />
                </ValidationProvider>
              </div>
              <v-col cols="2">
                <v-btn
                  color="primary"
                  :disabled="submittingFormDialog"
                  :loading="submittingFormDialog"
                  type="submit"
                  block
                  >Enviar Dados</v-btn
                >
              </v-col>
            </form>
          </ValidationObserver>
        </div>
      </v-list>
      <v-divider></v-divider>
      <v-list three-line subheader>
        <div class="d-flex flex-column ma-3">
          <template>
            <v-data-table
              :headers="table.headers"
              v-if="type === 'categoria'"
              :items="categoriaSalasList"
              :loading="carregandoCategoriaSalas"
              :items-per-page="10"
              :custom-filter="dataTableFilter"
              :search="search"
              class="elevation-1"
            >
              <template v-slot:body="{ items }">
                <tbody name="scroll-x-transition" is="transition-group" duration="150">
                  <tr
                    color="primary"
                    :class="categoria.estaDeletado ? ['deleted'] : []"
                    v-for="categoria of items"
                    :key="categoria.id"
                  >
                    <td>{{ categoria.id }}</td>
                    <td>{{ categoria.description ? categoria.description : "- - -" }}</td>
                    <td>
                      <v-tooltip v-if="!categoria.estaDeletado" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            x-small
                            class="ml-1"
                            color="blue-grey lighten-3"
                            v-bind="attrs"
                            v-on="on"
                            @click="() => editarCategoriaSala(categoria)"
                          >
                            <v-icon small>fa fa-edit </v-icon>
                          </v-btn>
                        </template>
                        <span>Editar categoria</span>
                      </v-tooltip>
                      <v-tooltip v-if="!categoria.estaDeletado" color="error" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            x-small
                            class="ml-1"
                            color="error"
                            v-bind="attrs"
                            v-on="on"
                            @click="() => deletarCategoriaSala(categoria)"
                          >
                            <v-icon small>fa fa-trash </v-icon>
                          </v-btn>
                        </template>
                        <span>Apagar Categoria</span>
                      </v-tooltip>
                      <v-tooltip v-if="categoria.estaDeletado" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            x-small
                            class="ml-1"
                            color="info"
                            v-bind="attrs"
                            v-on="on"
                            @click="() => recuperarCategoria(categoria)"
                          >
                            <v-icon small>fa fa-sync </v-icon>
                          </v-btn>
                        </template>
                        <span>Recuperar categoria</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
            <v-data-table
              :headers="table.headers"
              v-else-if="type === 'subcategoria'"
              :items="subcategoriaSalasList"
              :loading="carregandoCategoriaSalas"
              :items-per-page="10"
              :custom-filter="dataTableFilter"
              :search="search"
              class="elevation-1"
            >
              <template v-slot:body="{ items }">
                <tbody name="scroll-x-transition" is="transition-group" duration="150">
                  <tr
                    color="primary"
                    :class="categoria.estaDeletado ? ['deleted'] : []"
                    v-for="categoria of items"
                    :key="categoria.id"
                  >
                    <td>{{ categoria.id }}</td>
                    <td>{{ categoria.description ? categoria.description : "- - -" }}</td>
                    <td>
                      <v-tooltip v-if="!categoria.estaDeletado" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            x-small
                            class="ml-1"
                            color="blue-grey lighten-3"
                            v-bind="attrs"
                            v-on="on"
                            @click="() => editarCategoriaSala(categoria)"
                          >
                            <v-icon small>fa fa-edit </v-icon>
                          </v-btn>
                        </template>
                        <span>Editar categoria</span>
                      </v-tooltip>
                      <v-tooltip v-if="!categoria.estaDeletado" color="error" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            x-small
                            class="ml-1"
                            color="error"
                            v-bind="attrs"
                            v-on="on"
                            @click="() => deletarCategoriaSala(categoria)"
                          >
                            <v-icon small>fa fa-trash </v-icon>
                          </v-btn>
                        </template>
                        <span>Apagar Categoria</span>
                      </v-tooltip>
                      <v-tooltip v-if="categoria.estaDeletado" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            x-small
                            class="ml-1"
                            color="info"
                            v-bind="attrs"
                            v-on="on"
                            @click="() => recuperarCategoria(categoria)"
                          >
                            <v-icon small>fa fa-sync </v-icon>
                          </v-btn>
                        </template>
                        <span>Recuperar categoria</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </template>
        </div>
      </v-list>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { dataTableFilter } from "@/plugins/searchQuery";
import ELabel from "../ELabel.vue";

export default {
  components: { ELabel },
  props: {
    editingDialog: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "categoria",
    },
  },
  computed: {
    ...mapGetters("CategoriaSalas", ["categoriaSalasList", "carregandoCategoriaSalas"]),
    ...mapGetters("SubcategoriaSalas", ["subcategoriaSalasList", "carregandoSubcategoriaSalas"]),
    form() {
      if (!this.categorias || !this.editingDialog) return {};
      const { categoria_id } = this.$route.params;
      const categoria = this.categorias.get(parseInt(categoria_id, 10));
      return { ...categoria };
    },
  },
  mounted() {
    this.loadData();
    if (this.type === "categoria") {
      this.$store.dispatch("CategoriaSalas/loadCategoriaSala");
    }
    if (this.type === "subcategoria") {
      this.$store.dispatch("SubcategoriaSalas/loadSubcategoriaSala");
    }
  },
  data() {
    return {
      isHidden: true,
      submittingFormDialog: false,
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
      dataTableFilter,
      search: "",
      description: "",
      status: "Cadastrar",
      categoriaEdit: {
        id: "",
        description: "",
      },
      table: {
        headers: [
          { text: "#", value: "id" },
          { text: "Descrição", value: "description" },
          { text: "Ações", value: "actions", sortable: false },
        ],
      },
    };
  },
  watch: {
    carregandoCategoriaSalas(value) {
      if (this.type === "categoria") {
        if (value && this.categoriaSalasList.length === 0) {
          this.$loaderService.open("Carregando categorias de salas");
        } else {
          this.$loaderService.close();
        }
      }
      if (this.type === "subcategoria") {
        if (value && this.subcategoriaSalasList.length === 0) {
          this.$loaderService.open("Carregando subcategorias de salas");
        } else {
          this.$loaderService.close();
        }
      }
    },
  },
  methods: {
    ...mapActions("CategoriaSalas", ["loadCategoriaSala"]),
    ...mapActions("SubcategoriaSalas", ["loadSubcategoriaSala"]),
    deixarLetraMinuscula(value, campo) {
      const novo_valor = value.toLowerCase().split(" ");
      const valor = novo_valor
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
      this.form[campo] = valor;
    },
    async submitFormDialog() {
      this.submittingFormDialog = true;
      try {
        if (this.type === "categoria") {
          if (this.editingDialog) {
            this.form.description = this.description;
            this.form.id = this.categoriaEdit.id;
            await this.$services.categoriaSalaService.atualizarCategoriaSala(this.form);
            this.editingDialog = false;
            this.description = "";
            this.status = "Cadastrar";
            this.$toast.success("Categoria de Sala Atualizada com sucesso");
          } else {
            await this.$services.categoriaSalaService.criarCategoriaSala(this.form);
            this.description = "";
            this.$toast.success("Categoria de Sala Cadastrada com sucesso");
          }
          this.$store.dispatch("CategoriaSalas/loadCategoriaSala");
        }
        if (this.type === "subcategoria") {
          if (this.editingDialog) {
            this.form.description = this.description;
            this.form.id = this.categoriaEdit.id;
            await this.$services.subcategoriaSalaService.atualizarSubcategoriaSala(this.form);
            this.editingDialog = false;
            this.description = "";
            this.status = "Cadastrar";
            this.$toast.success("Subcategoria de Sala Atualizada com sucesso");
          } else {
            await this.$services.subcategoriaSalaService.criarSubcategoriaSala(this.form);
            this.description = "";
            this.$toast.success("Subcategoria de Sala Cadastrada com sucesso");
          }
          this.$store.dispatch("SubcategoriaSalas/loadSubcategoriaSala");
        }
      } catch (error) {
        this.$handleError(error);
      }
      this.submittingFormDialog = false;
    },
    async loadData() {
      if (this.type === "categoria") {
        try {
          this.$loaderService.open("Carregando dados");
          await Promise.all([this.loadCategoriaSala()]);
          this.$loaderService.close();
          this.$forceUpdate();
        } catch (error) {
          this.$handleError(error);
        }
      }
      if (this.type === "subcategoria") {
        try {
          this.$loaderService.open("Carregando dados");
          await Promise.all([this.loadSubcategoriaSala()]);
          this.$loaderService.close();
          this.$forceUpdate();
        } catch (error) {
          this.$handleError(error);
        }
      }
    },
    async deletarCategoriaSala(categoriaSala) {
      this.$modals.danger({
        title: "Cuidado!",
        message:
          "Tem certeza que deseja apagar esta categoria de sala? Todas as salas vinculadas serão apagadas",
        confirmationCode: categoriaSala.id,
        confirmationMessage: `Por favor, digite <strong>${categoriaSala.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Deletando a Categoria");
              if (this.type === "categoria") {
                try {
                  await this.$services.categoriaSalaService.deletarCategoriaSala(categoriaSala);
                  this.$store.dispatch("CategoriaSalas/loadCategoriaSala");
                  this.$toast.success("Categoria deletada com sucesso");
                  this.$forceUpdate();
                } catch (error) {
                  this.$handleError(error);
                }
                this.$loaderService.close();
              }
              if (this.type === "subcategoria") {
                try {
                  await this.$services.subcategoriaSalaService.deletarSubcategoriaSala(
                    categoriaSala
                  );
                  this.$store.dispatch("SubcategoriaSalas/loadSubcategoriaSala");
                  this.$toast.success("Subcategoria deletada com sucesso");
                  this.$forceUpdate();
                } catch (error) {
                  this.$handleError(error);
                }
                this.$loaderService.close();
              }
            },
            props: {
              color: "error",
              // text: true,
              small: true,
            },
          },
        ],
      });
    },
    editarCategoriaSala(categoriaSala) {
      this.description = categoriaSala.description;
      this.editingDialog = true;
      this.categoriaEdit = {
        id: categoriaSala.id,
        description: categoriaSala.description,
      };
      this.status = "Editar";
    },
  },
};
</script>
