import { render, staticRenderFns } from "./EditAulaInfantil.vue?vue&type=template&id=0545ffd8&scoped=true&"
import script from "./EditAulaInfantil.vue?vue&type=script&lang=js&"
export * from "./EditAulaInfantil.vue?vue&type=script&lang=js&"
import style0 from "./EditAulaInfantil.vue?vue&type=style&index=0&id=0545ffd8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0545ffd8",
  null
  
)

export default component.exports