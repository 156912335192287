import MapCollection from "collections/map";
import Vue from "vue";
import categoriaSalaService from "@/Services/CategoriaSalaService";
import $handleError from "@/plugins/handleError";

const state = () => ({
  categoriaSalas: [],
  carregandoCategoriaSalas: false,
  categoriaSalaLoaded: false,
  categoriaSalasList: [],
});
const mutations = {
  setCategoriaSala(state, categoriaSalas) {
    const categoriaSalasMap = new MapCollection();
    categoriaSalas.map((categoriaSala) => categoriaSalasMap.set(categoriaSala.id, categoriaSala));
    Vue.set(state, "categoriaSalas", categoriaSalasMap);
    Vue.set(state, "categoriaSalasList", Array.from(categoriaSalasMap.values()));
    Vue.set(state, "categoriaSalasLoaded", true);
    // state = {...state, salas: salasMap, salasLoaded: true}
  },
  setCarregandoCategoriaSalas(state, newValue) {
    state.carregandoCategoriaSalas = newValue;
  },
  adicionarCategoriaSala(state, categoria) {
    state.categoriaSalas.set(categoria.id, categoria);
    Vue.set(state, "categoriaSalas", state.categoriaSalas);
    Vue.set(state, "categoriaSalasList", Array.from(state.categoriaSalas.values()));
  },
  atualizarCategoriaSala(state, categoria) {
    state.categorias.set(categoria.id, categoria);
    Vue.set(state, "categorias", state.categorias);
    Vue.set(state, "categoriasList", Array.from(state.categorias.values()));
  },
  deletarSala(state, categoriaId) {
    state.categorias.delete(categoriaId);
    Vue.set(state, "categorias", state.categorias);
    Vue.set(state, "categoriasList", Array.from(state.salas.values()));
  },
};
const actions = {
  async loadCategoriaSala({ commit }, options = {}) {
    commit("setCarregandoCategoriaSalas", true);
    try {
      const categoriaSalasList = await categoriaSalaService.syncAll(options.force);
      commit("setCategoriaSala", categoriaSalasList);
    } catch (error) {
      $handleError(error);
    }
    commit("setCarregandoCategoriaSalas", false);
  },
};
const getters = {
  categoriaSalas: (state) => state.categoriaSalas,
  carregandoCategoriaSalas: (state) => state.carregandoCategoriaSalas,
  categoriaSalasLoaded: (state) => state.categoriaSalasLoaded,
  categoriaSalasList: (state) => state.categoriaSalasList,
};
const namespaced = true;
export default {
  state,
  mutations,
  actions,
  getters,
  namespaced,
};
